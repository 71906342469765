import React from 'react';
import { Heading, Flex, Grid, GridItem } from '@chakra-ui/react';
import { PermissionsGate } from 'components';
import { Title } from 'components/Meta';
import { formContext } from 'components/Form.Context';

interface ReactNode {
  children?: React.ReactNode;
  [key: string]: any;
}

export interface FormLayoutProps extends ReactNode {
  template?: 'page' | 'modal';
  title?: string;
  selector?: Record<string, any>;
}

//TODO: do przerzucenia do skórki
const PAGE_TEMPLATE = `
"heading heading"
"status empty"
"body body"
"actions actions"
`;

const MODAL_TEMPLATE = `
"heading heading"
"status empty"
"body body"
"actions actions"
`;

const VARIANTS = {
  page: PAGE_TEMPLATE,
  modal: MODAL_TEMPLATE,
};

const FormLayout = ({
  children,
  template = 'page',
  ...props
}: FormLayoutProps): JSX.Element => (
  <Grid
    templateAreas={VARIANTS[template]}
    templateColumns='1fr 50%'
    gap={4}
    {...props}
  >
    {children}
  </Grid>
);

const FormHeading = ({
  title,
  children,
  ...props
}: ReactNode & { title?: string }): JSX.Element => (
  <Heading gridArea='heading' {...props}>
    {title && <Title name={title} />}
    {title}
    {children}
  </Heading>
);

const FormStatus = ({ children, ...props }: ReactNode): JSX.Element => (
  <GridItem gridArea='status' {...props}>
    {children}
  </GridItem>
);

const FormActions = ({ children, ...props }: ReactNode): JSX.Element => {
  const { permissionsToEdit } = React.useContext(formContext);
  return (
    <PermissionsGate requiredPermissions={permissionsToEdit}>
      <GridItem
        as={Flex}
        justifyContent='flex-end'
        alignItems='flex-end'
        gridGap={4}
        gridArea='actions'
        {...props}
      >
        {children}
      </GridItem>
    </PermissionsGate>
  );
};

const FormBody = ({ children, ...props }: ReactNode): JSX.Element => (
  <GridItem gridArea='body' w='100%' {...props}>
    {children}
  </GridItem>
);

FormLayout.Heading = FormHeading;
FormLayout.Actions = FormActions;
FormLayout.Body = FormBody;
FormLayout.Status = FormStatus;

export { FormLayout };
