import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { NO_CURRENT_ENTITY } from 'consts/store';
import { setCurrent as setCurrentCampaign } from 'store/campaigns';
import { setCurrent as setCurrentCreative } from 'store/creatives';
import { setCurrent as setCurrentLineitem } from 'store/lineitems';
import { setCurrentClient } from 'store/clients';
import { setCurrentProposal } from 'store/proposals';
import { setCurrentProduct } from 'store/products';

type UrlParamType = {
  campaignId: number | null;
  creativeId: number | null;
  lineitemId: number | null;
  clientId: number | null;
  proposalId: number | null;
  productId: number | null;
};

export const UrlParamsHandler = (): null => {
  const params = useParams<{
    campaignId?: string;
    creativeId?: string;
    lineitemId?: string;
    clientId?: string;
    proposalId?: string;
    productId?: string;
  }>();
  const dispatch = useAppDispatch();
  const [parsedParams, setParsedParams] = React.useState<UrlParamType>({
    campaignId: NO_CURRENT_ENTITY,
    creativeId: NO_CURRENT_ENTITY,
    lineitemId: NO_CURRENT_ENTITY,
    clientId: NO_CURRENT_ENTITY,
    proposalId: NO_CURRENT_ENTITY,
    productId: NO_CURRENT_ENTITY,
  });

  React.useEffect(() => {
    const {
      campaignId,
      creativeId,
      lineitemId,
      clientId,
      proposalId,
      productId,
    } = params;

    setParsedParams({
      campaignId: campaignId ? Number.parseInt(campaignId) : NO_CURRENT_ENTITY,
      creativeId: creativeId ? Number.parseInt(creativeId) : NO_CURRENT_ENTITY,
      lineitemId: lineitemId ? Number.parseInt(lineitemId) : NO_CURRENT_ENTITY,
      clientId: clientId ? Number.parseInt(clientId) : NO_CURRENT_ENTITY,
      proposalId: proposalId ? Number.parseInt(proposalId) : NO_CURRENT_ENTITY,
      productId: productId ? Number.parseInt(productId) : NO_CURRENT_ENTITY,
    });
  }, [params]);

  React.useEffect(() => {
    dispatch(setCurrentCampaign(parsedParams.campaignId));
  }, [parsedParams?.campaignId]);

  React.useEffect(() => {
    dispatch(setCurrentLineitem(parsedParams.lineitemId));
  }, [parsedParams?.lineitemId]);

  React.useEffect(() => {
    dispatch(setCurrentCreative(parsedParams.creativeId));
  }, [parsedParams?.creativeId]);

  React.useEffect(() => {
    dispatch(setCurrentClient(parsedParams.clientId));
  }, [parsedParams?.clientId]);

  React.useEffect(() => {
    dispatch(setCurrentProposal(parsedParams.proposalId));
  }, [parsedParams?.proposalId]);

  React.useEffect(() => {
    dispatch(setCurrentProduct(parsedParams.productId));
  }, [parsedParams?.productId]);

  return null;
};
