import * as React from 'react';
import {
  Box,
  Button,
  InputProps,
  UnorderedList,
  ListItem,
  useFormControl,
  useMultiStyleConfig,
  useToast,
  Icon,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import {
  useDropzone,
  FileWithPath,
  Accept,
  FileRejection,
} from 'react-dropzone';
import adserver from 'services/adserver';
import { formatApiErrors } from 'helpers';
import { FiTrash, FiDownload } from 'react-icons/fi';
import { selectCurrentProposalId } from 'store/proposals/proposals.selectors';
import { useAppSelector } from 'hooks';
import { useProposalStatusID } from 'hooks/useProposalStatus';
import {
  PUBLISHER_GENERATE_ORDER_REQUESTED,
  PUBLISHER_CORRECTION_REQUESTED,
  PUBLISHER_ACCEPTANCE_REQUESTED,
  BUYER_SIGNATURE_REQUESTED,
  BUYER_CORRECTION_REQUESTED,
} from 'store/proposals';
import { isUserBuyer } from 'store/user';
import axios from 'axios';

type FileDataType = {
  name?: string;
  uploadedFile?: string;
};

export type InputFileWithUploadType = {
  fileId?: number;
  accept?: Accept;
  minSize?: number;
  maxSize?: number;
  isDisabled?: boolean;
  onChange: (fileId?: number) => void;
};

/**
 * Komponent obsługujący upload plików na potrzeby kreacji
 * Plik jest wysyłany do endpointa API, zwracane jest id, które to staje się wartością pola formularza
 */
export const InputFileProposal = ({
  fileId,
  isDisabled,
  accept,
  onChange,
  size,
  variant,
  minSize,
  maxSize,
  ...props
}: InputFileWithUploadType & InputProps): JSX.Element => {
  const toast = useToast({
    position: 'bottom',
    status: 'error',
    isClosable: true,
  });
  const chakraInputProps = useFormControl(props);
  const chakraInputStyle = useMultiStyleConfig('InputFile', {
    size,
    variant,
  });
  const [fileData, setFileData] = React.useState<FileDataType | undefined>();
  /**
   * status uploadowania pliku: undefined dla idle lub cyfra 0-100 oznaczająca procent uploadu
   */
  const [uploadingProgress, setUploadingProgesss] = React.useState<
    number | undefined
  >();

  const proposalId = useAppSelector(selectCurrentProposalId);
  const isBuyer = useAppSelector(isUserBuyer);
  const hasPublisherStatuses = useProposalStatusID(
    [
      PUBLISHER_GENERATE_ORDER_REQUESTED,
      PUBLISHER_CORRECTION_REQUESTED,
      PUBLISHER_ACCEPTANCE_REQUESTED,
    ],
    proposalId,
  );
  const hasBuyerStatuses = useProposalStatusID(
    [BUYER_SIGNATURE_REQUESTED, BUYER_CORRECTION_REQUESTED],
    proposalId,
  );

  const handleAcceptedFiles = (acceptedFiles: FileWithPath[]) => {
    if (acceptedFiles.length) {
      uploadFile(acceptedFiles[0]);
    }
  };

  const handleRejectedFiles = (fileRejections: FileRejection[]) => {
    fileRejections.forEach(fileRejection => {
      toast({
        title: 'Plik odrzucony',
        description: (
          <UnorderedList>
            {fileRejection.errors.map(error => (
              <ListItem key={error.code}>{error.message}</ListItem>
            ))}
          </UnorderedList>
        ),
      });
    });
  };

  const handleClearFile = () => {
    setFileData(undefined);
    if (onChange) onChange(undefined);
  };

  const handleDwonloadFile = async () => {
    if (fileData?.name && fileData?.uploadedFile) {
      const { data } = await axios.get(fileData?.uploadedFile, {
        responseType: 'blob',
      });
      const url = URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileData.name);
      document.body.appendChild(link);
      link.click();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleAcceptedFiles,
    onDropRejected: handleRejectedFiles,
    maxFiles: 1,
    disabled:
      isDisabled && (isBuyer ? !hasBuyerStatuses : !hasPublisherStatuses),
    accept,
    minSize,
    maxSize,
  });

  async function getUploadedFileData(fileId: number) {
    const result = await adserver({
      url: `/proposal/${proposalId}/order-value/${fileId}`,
      headers: {},
    });
    setFileData(result.data);
  }

  async function uploadFile(file: FileWithPath) {
    try {
      setFileData({
        name: file.name,
        uploadedFile: URL.createObjectURL(file),
      });

      const url = `/proposal/${proposalId}/order-value/`;
      const method = 'POST';
      const data = new FormData();
      data.append('uploadedFile', file);
      data.append('name', file.name);

      const result = await adserver({
        url,
        method,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (status: { loaded: number; total: number }) =>
          setUploadingProgesss(
            Math.round((status.loaded * 100) / status.total),
          ),
      });

      setFileData(result.data);

      if (!onChange) return;
      onChange(result.data?.id || undefined);
    } catch (error) {
      toast({
        title: 'Błąd podczas wysyłania pliku',
        description: formatApiErrors(error),
      });

      setFileData(undefined);

      if (onChange) onChange(undefined);
    }
    setUploadingProgesss(undefined);
  }

  React.useEffect(() => {
    if (fileId) {
      getUploadedFileData(fileId);
    } else {
      setFileData(undefined);
    }
  }, [fileId]);

  return (
    <Flex alignContent='stretch' alignItems='center'>
      {fileData?.uploadedFile && (
        <IconButton
          m={2}
          aria-label={'Pobierz plik'}
          onClick={handleDwonloadFile}
          icon={<Icon as={FiDownload} />}
          isDisabled={false}
        />
      )}
      <Box
        flex='auto'
        __css={chakraInputStyle.field}
        {...chakraInputProps}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box __css={chakraInputStyle.heading}>{fileData?.name}</Box>
        <Box __css={chakraInputStyle.details}>
          {uploadingProgress && <Box>{uploadingProgress}%</Box>}
        </Box>
        <Button
          colorScheme='gray'
          size='sm'
          sx={chakraInputStyle.action}
          isDisabled={
            isDisabled && (isBuyer ? !hasBuyerStatuses : !hasPublisherStatuses)
          }
        >
          Wybierz plik
        </Button>
      </Box>
      <IconButton
        m={2}
        aria-label={'Usuń plik'}
        onClick={handleClearFile}
        icon={<Icon as={FiTrash} />}
        isDisabled={
          isDisabled && (isBuyer ? !hasBuyerStatuses : !hasPublisherStatuses)
        }
      />
    </Flex>
  );
};
