import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Input } from '@chakra-ui/react';

const InputCurrency = ({
  onChange,
  brColor,
  value,
  ...props
}: {
  isDisabled?: boolean;
  brColor?: string;
  value: string | number;
  onChange: (value: string | number) => void;
  [key: string]: any;
}): JSX.Element => {
  const [inputValue, setInputValue] = React.useState(value);
  const handleChange = (value?: string, name?: string, values?: any) => {
    if (!value) {
      setInputValue('0,00');
      onChange(0.0);
      return;
    }
    setInputValue(value);
    onChange(values.float);
  };

  return (
    <Input
      as={CurrencyInput}
      decimalScale={2}
      allowNegativeValue={false}
      allowDecimals={true}
      groupSeparator=' '
      decimalSeparator=','
      onValueChange={handleChange}
      value={inputValue}
      borderColor={brColor}
      {...props}
    />
  );
};

export { InputCurrency };
