/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
export default {
  baseStyle: {
    track: {
      bg: 'graphite.400',
      _checked: {
        bg: 'nexusTeal.100',
      },
      _hover: {
        boxShadow: '0 0 0 3px rgba(203, 213, 224, 0.6)',
      },
      _focus: {
        boxShadow: '',
      },
    },
    thumb: {
      bg: 'white',
      boxShadow: '0 0 4px 0 rgba(49, 49, 77, 0.2)',
    },
  },
};
