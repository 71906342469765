import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './adslots.consts';
import { AdSlotParams } from './adslots.types';

const fetchByIdHandler = async ({ adSlotId }: { adSlotId: number }) => {
  const response = await adserver({
    url: `/adslots/${adSlotId}`,
    headers: {},
  });

  return {
    ...response.data,
  };
};

export const fetchAllAdSlots = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/adslots/', headers: {} });
    return response.data;
  }),
);

export const fetchById = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ adSlotId }: { adSlotId: number }) => {
    const response = await adserver({
      url: `/adslots/${adSlotId}/`,
      headers: {},
    });
    return response.data;
  }),
);

export const create = createAsyncThunk(
  REDUCER_KEY + '/create',
  withErrorHandling(async (values: AdSlotParams) => {
    const response = await adserver({
      url: '/adslots/',
      method: 'POST',
      data: values,
      headers: {},
    });
    return response.data;
  }),
);

export const update = createAsyncThunk(
  REDUCER_KEY + '/update',
  withErrorHandling(async (data: AdSlotParams) => {
    const { id: adSlotId } = data;

    const response = await adserver({
      url: `/adslots/${adSlotId}/`,
      method: 'PATCH',
      data,
      headers: {},
    });
    return response.data;
  }),
);

export const remove = createAsyncThunk(
  REDUCER_KEY + '/remove',
  withErrorHandling(async ({ adSlotId }: { adSlotId: number }) => {
    await adserver({
      url: `/adslots/${adSlotId}/`,
      method: 'DELETE',
      headers: {},
    });
    return adSlotId;
  }),
);

export const setCurrentAdSlot = createAsyncThunk(
  REDUCER_KEY + '/current',
  withErrorHandling(async (adSlotId: number) => {
    if (!adSlotId) return;
    const user = await fetchByIdHandler({ adSlotId });
    return user;
  }),
);

export const removeAllAdSlots = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
