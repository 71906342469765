/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { merge, pick } from 'lodash';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { getHistoryOfCampaign } from 'store/history/history.actions';
import { selectCurrentCampaignId } from 'store/campaigns';
import { NAME } from './creatives.consts';
import { EDITABLE_FIELDS } from 'consts/creative';

/**
 * wspólny handler dla akcji akcji aktualizujących dane kreacji
 *
 * @param {*} { campaignId, creativeId }
 * @param {*} thunkApi
 * @return {*} creativeObj
 */
const fetchByIdHandler = async ({ campaignId, creativeId }) => {
  const response = await adserver({
    url: `/campaign/${campaignId}/creative/${creativeId}`,
  });
  const fetchedAt = Date.now();

  return { ...response.data, fetchedAt, campaignId };
};

/**
 * wspólny handler dla akcji pobierających dane wszystkich lineitemów dla danej kampanii
 *
 * @param {*} { campaignId, lineitemId }
 * @param {*} thunkApi
 * @return {*} lineitem
 */
const handleFetchAllCreativesForCampaign = async ({ campaignId }) => {
  const response = await adserver({
    url: `/campaign/${campaignId}/creatives`,
  });
  const fetchedAt = Date.now();
  return response.data.map(creative => ({
    ...creative,
    fetchedAt,
    campaignId,
  }));
};

export const fetchAll = createAsyncThunk(
  NAME + '/fetchAll',
  withErrorHandling(handleFetchAllCreativesForCampaign),
);

export const fetchAllCreativesForCurrentCampaign = createAsyncThunk(
  NAME + '/fetchAllCreativesForCurrentCampaign',
  withErrorHandling(async (_, thunkApi) => {
    const campaignId = selectCurrentCampaignId(thunkApi.getState());
    return await handleFetchAllCreativesForCampaign({ campaignId });
  }),
);

export const fetchById = createAsyncThunk(
  NAME + '/fetchById',
  withErrorHandling(fetchByIdHandler),
);

export const create = createAsyncThunk(
  NAME + '/create',
  withErrorHandling(async (data, thunkAPI) => {
    const { campaignId } = data;
    if (!campaignId) throw new Error('no campaignId');
    const response = await adserver({
      url: `/campaign/${campaignId}/creative`,
      method: 'POST',
      data,
    });
    thunkAPI.dispatch(getHistoryOfCampaign(campaignId));
    return response.data;
  }),
);

export const update = createAsyncThunk(
  NAME + '/update',
  withErrorHandling(async (data, thunkAPI) => {
    const { campaignId, creativeId } = data;

    const response = await adserver({
      url: `/campaign/${campaignId}/creative/${creativeId}`,
      method: 'PATCH',
      data,
    });
    thunkAPI.dispatch(getHistoryOfCampaign(campaignId));
    return response.data;
  }),
);

export const remove = createAsyncThunk(
  NAME + '/remove',
  withErrorHandling(async ({ campaignId, creativeId }) => {
    await adserver({
      url: `/campaign/${campaignId}/creative/${creativeId}`,
      method: 'DELETE',
    });
    return creativeId;
  }),
);

export const setCurrent = createAsyncThunk(
  NAME + '/current',
  withErrorHandling(async (creativeId, thunkApi) => {
    if (!creativeId) return;
    const campaignId = selectCurrentCampaignId(thunkApi.getState());

    const creative = await fetchByIdHandler({ campaignId, creativeId });
    return creative;
  }),
);

export const livepreview = createAsyncThunk(
  NAME + '/livepreview',
  withErrorHandling(async ({ campaignId, creativeId, exchange }) => {
    const response = await adserver({
      url: `/campaign/${campaignId}/creative/${creativeId}/livepreview/${exchange}`,
    });
    return response.data;
  }),
);

export const removeAllCreatives = () => ({ type: NAME + '/removeAll' });
