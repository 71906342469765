import { thirtyDaysLater, startOfToday } from 'consts/dates';
import * as Yup from 'yup';

export const READ_ONLY_FIELDS = {
  id: Yup.number().integer(),
  isActive: Yup.bool().required().default(false),
};

export const EDITABLE_FIELDS = {
  name: Yup.string().required().default('').label('Nazwa'),
  startDate: Yup.date().default(startOfToday).label('Data startu'),
  endDate: Yup.date().default(thirtyDaysLater).label('Data zakończenia'),
  products: Yup.array().of(Yup.number()).default([]).label('Produkty'),
  mediaAgency: Yup.number()
    .integer()
    .default(null)
    .nullable()
    .label('Agencja reklamowa'),
  client: Yup.number()
    .integer()
    .required()
    .default(null)
    .nullable()
    .label('Klient'),
};

export const HISTORY_FIELDS = {
  active: Yup.bool().label('Campaign is active'),
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape(EDITABLE_FIELDS);

export const campaignSchema = Yup.object().shape({
  ...READ_ONLY_FIELDS,
  ...EDITABLE_FIELDS,
  ...HISTORY_FIELDS,
});

export type CampaignInterface = Yup.InferType<typeof campaignSchema>;
