import {
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import {
  Fieldset,
  Form,
  PermissionsGate,
  ResetButton,
  SubmitButton,
} from 'components';
import { FormProps } from 'components/Form';
import { FormSkeleton } from 'components/Skeletons';
import { CAN_EDIT_CAMPAIGNS } from 'consts/permissions';
import { isBudgetVisible } from 'helpers/flagHelpers';
import { useAppSelector } from 'hooks';
import { FormLayout, FormLayoutProps } from 'layouts';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { selectCurrentCampaign } from 'store/campaigns';
import * as Field from '../formFields';
import { lineitemShowPage } from '../Lineitem.Links';
import { validationSchema } from '../Lineitem.schema';

const FormSectionGeneral = (): JSX.Element => {
  const campaign = useAppSelector(selectCurrentCampaign);

  return (
    <Grid gap={2} templateColumns='repeat(2,1fr)'>
      <Fieldset gridColumn='span 2'>
        <Field.LineitemGeneralName gridColumn='span 12' />
        {campaign?.budgetPerLineitem && isBudgetVisible() && (
          <Field.LineitemTotalBudget gridColumn='span 6' />
        )}
      </Fieldset>
      <Fieldset heading='Event Plan' gridColumn='span 2' columns={4}>
        <Field.LineitemGeneralEventPlanEventCount gridColumn='span 1' />
        <Field.LineitemGeneralEventPlanEventType gridColumn='span 1' />
        <Field.LineitemGeneralEventPlanEventPlanType gridColumn='span 1' />
        <Field.LineitemGeneralEventPlanPaceType gridColumn='span 1' />
      </Fieldset>
      <Fieldset heading='Minimal CTR' gridColumn='span 1' columns={2}>
        <Field.LineitemGeneralMinimalCtrValue gridColumn='span 1' />
        <Field.LineitemGeneralMinimalCtrIsActive gridColumn='span 1' />
      </Fieldset>
      <Fieldset heading='Frequency Capping' columns={3}>
        <Field.LineitemGeneralFrequencyCappingCount gridColumn='span 1' />
        <Field.LineitemGeneralFrequencyCappingPeriod gridColumn='span 1' />
        <Field.LineitemGeneralFrequencyCappingEnabled gridColumn='span 1' />
      </Fieldset>
    </Grid>
  );
};

const FormSectionTiming = (): JSX.Element => (
  <Grid gap={2}>
    <Fieldset columns={12} heading='Duration'>
      <Field.LineitemGeneralTimingStartDate gridColumn='span 4' />
      <Field.LineitemGeneralTimingEndDate gridColumn='span 4' />
    </Fieldset>
    <Fieldset columns={12} heading='Limit to'>
      <Field.LineitemGeneralTimingDailyTiming gridColumn='span 8' />
      <Field.LineitemGeneralTimingHourlyTiming gridColumn='span 3' />
    </Fieldset>
  </Grid>
);

const FormSectionCreatives = (): JSX.Element => (
  <Fieldset columns={1} variant='ghost'>
    <Field.CreativesList />
  </Fieldset>
);

export const LineitemFormFieldsAsTabs = ({
  generalSection,
}: {
  generalSection: ReactNode;
}): JSX.Element => (
  <Tabs isLazy>
    <TabList>
      <Tab>General settings</Tab>
      <Tab>Timing settings</Tab>
      <Tab>Targeting options</Tab>
      <Tab>Creatives</Tab>
    </TabList>

    <TabPanels>
      <TabPanel>{generalSection}</TabPanel>
      <TabPanel>
        <FormSectionTiming />
      </TabPanel>
      <TabPanel>
        <Tabs isLazy>
          <TabList>
            <Tab>General</Tab>
            <Tab>Location</Tab>
            <Tab>Inventory</Tab>
            <Tab>Demographic</Tab>
          </TabList>
        </Tabs>
      </TabPanel>
      <TabPanel>
        <FormSectionCreatives />
      </TabPanel>
    </TabPanels>
  </Tabs>
);

export const LineitemFormFields = (): JSX.Element => (
  <LineitemFormFieldsAsTabs generalSection={<FormSectionGeneral />} />
);

export const LineitemFormActions = (): JSX.Element => (
  <PermissionsGate requiredPermissions={[CAN_EDIT_CAMPAIGNS]}>
    <FormLayout.Actions>
      <SubmitButton />
      <ResetButton />
    </FormLayout.Actions>
  </PermissionsGate>
);

interface LineitemFormProps extends FormProps, FormLayoutProps {
  redirectOnSuccess?: boolean;
}
export const LineitemForm = ({
  defaultValues,
  template,
  redirectOnSuccess,
  children,
  onSubmit,
  ...props
}: LineitemFormProps): JSX.Element => {
  const history = useHistory();

  function handleSubmit(lineitem: any): void {
    if (lineitem.id && redirectOnSuccess) {
      history.push(lineitemShowPage(lineitem.id));
    }
    if (typeof onSubmit === 'function') onSubmit(lineitem);
  }

  if (!defaultValues) return <FormSkeleton />;

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      schema={validationSchema(isBudgetVisible())}
      permissionsToEdit={[CAN_EDIT_CAMPAIGNS]}
      {...props}
    >
      <FormLayout template={template}>{children}</FormLayout>
    </Form>
  );
};
