import { MultiOptionField, SingleField } from 'components/ModalInfo.Fields';
import { useAppSelector } from 'hooks';
import * as React from 'react';
import { selectadSlotsAsOptions } from 'store/adslots';

const ProductAdSlotsInfo = ({
  value,
  gridColumn,
  ...props
}: {
  value: number[] | undefined;
  gridColumn: any;
  [key: string]: any;
}): JSX.Element => {
  const adSlots = useAppSelector(selectadSlotsAsOptions);

  if (value === undefined || value.length === 0) {
    return (
      <SingleField
        header='Sloty reklamowe'
        value='Brak'
        gridColumn={gridColumn}
        {...props}
      />
    );
  }
  const elements = adSlots
    .filter(({ id }: { id: number }) => value.includes(id))
    .map(({ name }: { name: string }) => name);

  return (
    <MultiOptionField
      header='Sloty reklamowe'
      elements={elements}
      gridColumn={gridColumn}
      {...props}
    />
  );
};

ProductAdSlotsInfo.displayName = 'Product.adSlotsInfo';

export { ProductAdSlotsInfo };
