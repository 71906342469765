import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
} from 'date-fns';

const WEEK_OPTIONS = { weekStartsOn: 1 };

export const startOfYesterday = startOfDay(addDays(new Date(), -1));
export const endOfYesterday = endOfDay(addDays(new Date(), -1));
export const startOfToday = startOfDay(new Date());
export const endOfToday = endOfDay(new Date());
export const startOfTomorrow = startOfDay(addDays(new Date(), +1));
export const endOfTomorrow = endOfDay(addDays(new Date(), +1));

export const startOfLastWeek = startOfWeek(
  addDays(new Date(), -7),
  WEEK_OPTIONS,
);
export const endOfLastWeek = endOfWeek(addDays(new Date(), -7), WEEK_OPTIONS);
export const startOfThisWeek = startOfWeek(new Date(), WEEK_OPTIONS);
export const endOfThisWeek = endOfWeek(new Date(), WEEK_OPTIONS);
export const startOfNextWeek = startOfWeek(
  addDays(new Date(), +7),
  WEEK_OPTIONS,
);
export const endOfNextWeek = endOfWeek(addDays(new Date(), +7), WEEK_OPTIONS);

export const startOfLastMonth = startOfMonth(addMonths(new Date(), -1));
export const endOfLastMonth = endOfMonth(addMonths(new Date(), -1));
export const startOfThisMonth = startOfMonth(new Date());
export const endOfThisMonth = endOfMonth(new Date());
export const startOfNextMonth = startOfMonth(addMonths(new Date(), +1));
export const endOfNextMonth = endOfMonth(addMonths(new Date(), +1));

export const thirtyDaysLater = addDays(new Date(), 30);
