import { createSlice } from '@reduxjs/toolkit';
import { NO_CURRENT_ENTITY } from 'consts/store';
import { DATA_UNDEFINED, NO_ERRORS } from 'consts/xhr';
import {
  pendingActionHandler,
  readyActionHandler,
  rejectActionHandler,
} from 'helpers/sliceHelpers';
import {
  create,
  fetchAllAdFormats,
  remove,
  setCurrentAdFormat as setCurrent,
  update,
} from './adformats.actions';
import adapter from './adfromats.adapter';
import { REDUCER_KEY } from './adformats.consts';

const slice = createSlice({
  name: REDUCER_KEY,
  initialState: {
    ...adapter.getInitialState(),
    status: DATA_UNDEFINED,
    errors: NO_ERRORS,
    current: NO_CURRENT_ENTITY,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAllAdFormats.fulfilled, (state, action) =>
        adapter.upsertMany(state, action.payload),
      )
      .addCase(create.fulfilled, (state, action) =>
        adapter.addOne(state, action.payload),
      )
      .addCase(update.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(remove.fulfilled, (state, action) =>
        adapter.removeOne(state, action.payload),
      )
      .addCase(setCurrent.pending, (state, action) => {
        state.current = action.meta.arg;
      })
      .addCase(setCurrent.fulfilled, (state, action) => {
        if (action.payload) {
          state.current = action.payload.id;
          adapter.upsertOne(state, action.payload);
        } else {
          state.current = NO_CURRENT_ENTITY;
        }
      })
      .addCase(REDUCER_KEY + '/removeAll', state => adapter.removeAll(state))
      .addMatcher(
        ({ type }) =>
          type.startsWith(REDUCER_KEY) && type.endsWith('fulfilled'),
        readyActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(REDUCER_KEY) && type.endsWith('pending'),
        pendingActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(REDUCER_KEY) && type.endsWith('rejected'),
        rejectActionHandler,
      );
  },
});

export const { reducer } = slice;
