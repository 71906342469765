import * as React from 'react';
import { setHours, setMinutes, format } from 'date-fns';
import { Input, InputProps, Flex } from '@chakra-ui/react';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm';
const DEFAULT_HOUR = 0;
const DEFAULT_MINUTE = 0;

interface InputDateProps {
  value: Date | undefined;
  min: Date | undefined;
  max: Date | undefined;
  withInputTime?: boolean;
  onChange: (value: any) => void;
  brColor?: string;
}
export const InputDate = ({
  value,
  min,
  max,
  withInputTime,
  onChange,
  isDisabled,
  brColor,
  ...props
}: InputDateProps & InputProps): JSX.Element => {
  const [date, setDate] = React.useState<Date | undefined>(value);
  const [inputValue, setInputValue] = React.useState({ date: '', time: '' });

  function handleInputDateChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const inputValue = e?.target?.valueAsNumber;
    if (!inputValue) return;

    const hours = date?.getHours() || DEFAULT_HOUR;
    const minutes = date?.getMinutes() || DEFAULT_MINUTE;

    let newDate = new Date(inputValue);
    newDate = setHours(newDate, hours);
    newDate = setMinutes(newDate, minutes);

    setDate(newDate);
  }

  function handleInputTimeChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const inputValue = e?.target?.valueAsNumber;
    if (inputValue === undefined || !date) return;

    const inputDate = new Date(inputValue);
    const hours = inputDate.getUTCHours();
    const minutes = inputDate.getUTCMinutes();

    let newDate = date;
    newDate = setHours(newDate, hours);
    newDate = setMinutes(newDate, minutes);

    setDate(newDate);
  }

  React.useEffect(() => {
    const newInputValue = {
      date: '',
      time: '',
    };

    if (date instanceof Date) {
      newInputValue.date = format(date, DATE_FORMAT);
      newInputValue.time = format(date, TIME_FORMAT);
    }
    setInputValue(newInputValue);

    if (typeof onChange === 'function') onChange(date);
  }, [date]);

  return (
    <>
      <Flex gap={2}>
        <Input
          width='16ch'
          type='date'
          value={inputValue.date}
          min={min && format(min, DATE_FORMAT)}
          max={max && format(max, DATE_FORMAT)}
          onChange={handleInputDateChange}
          isDisabled={isDisabled}
          borderColor={brColor}
          {...props}
        />
        {withInputTime && (
          <Input
            width='10ch'
            type='time'
            value={inputValue.time}
            onChange={handleInputTimeChange}
            isDisabled={isDisabled}
          />
        )}
      </Flex>
    </>
  );
};
