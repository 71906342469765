import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

import { selectCurrentCampaign } from 'store/campaigns';
import { selectCurrentCreative } from 'store/creatives';
import { selectCurrentLineitem } from 'store/lineitems';
import { selectCurrentClient } from 'store/clients';
import { selectCurrentProposal } from 'store/proposals';
import { selectCurrentProduct } from 'store/products';

import {
  CAMPAIGN_LIST,
  CAMPAIGN_SHOW,
  CREATIVE_LIST,
  CREATIVE_SHOW,
  LINEITEM_LIST,
  LINEITEM_SHOW,
  CLIENT_LIST,
  CLIENT_SHOW,
  PROPOSAL_LIST,
  PROPOSAL_SHOW,
  PRODUCT_LIST,
  PRODUCT_SHOW,
  AD_FORMAT_LIST,
  AD_SLOT_LIST,
  AD_FORMAT_SHOW,
  AD_SLOT_SHOW,
  USER_LIST,
  USER_SHOW,
} from 'routes';
import { selectCurrentAdFormat } from 'store/adformats';
import { selectCurrentAdSlot } from 'store/adslots';
import { selectCurrentUser } from 'store/users';

const Breadcrumbs = ({ children, ...props }) => (
  <Breadcrumb fontSize='sm' fontWeight='medium' separator='»' {...props}>
    {children}
  </Breadcrumb>
);
Breadcrumbs.propTypes = {
  children: PropTypes.node.isRequired,
};

const Campaigns = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(CAMPAIGN_LIST)}>
      &lt; Powrót
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const Campaign = ({ ...props }) => {
  const { id: campaignId } = useSelector(selectCurrentCampaign);
  return (
    <BreadcrumbItem {...props}>
      {campaignId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(CAMPAIGN_SHOW, { campaignId })}
        >
          &lt; Powrót
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Clients = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(CLIENT_LIST)}>
      &lt; Powrót
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const Client = ({ ...props }) => {
  const { id: clientId } = useSelector(selectCurrentClient);
  return (
    <BreadcrumbItem {...props}>
      {clientId ? (
        <BreadcrumbLink as={Link} to={generatePath(CLIENT_SHOW, { clientId })}>
          &lt; Powrót
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Creatives = ({ ...props }) => {
  const { id: campaignId } = useSelector(selectCurrentCampaign);

  return (
    <BreadcrumbItem {...props}>
      {campaignId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(CREATIVE_LIST, { campaignId })}
        >
          Kreacje
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Creative = ({ ...props }) => {
  const { id: creativeId, campaignId, name } = useSelector(
    selectCurrentCreative,
  );
  return (
    <BreadcrumbItem {...props}>
      {creativeId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(CREATIVE_SHOW, { campaignId, creativeId })}
        >
          {name}
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Lineitems = ({ ...props }) => {
  const { id: campaignId } = useSelector(selectCurrentCampaign);

  return (
    <BreadcrumbItem {...props}>
      {campaignId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(LINEITEM_LIST, { campaignId })}
        >
          Lineitemy
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Lineitem = ({ ...props }) => {
  const { id: lineitemId, campaignId, general: { name } = {} } = useSelector(
    selectCurrentLineitem,
  );
  return (
    <BreadcrumbItem {...props}>
      {campaignId && lineitemId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(LINEITEM_SHOW, { campaignId, lineitemId })}
        >
          {name}
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Proposals = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(PROPOSAL_LIST)}>
      &lt; Powrót
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const Proposal = ({ ...props }) => {
  const { id: proposalId } = useSelector(selectCurrentProposal);
  return (
    <BreadcrumbItem {...props}>
      {proposalId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(PROPOSAL_SHOW, { proposalId })}
        >
          &lt; Powrót
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Products = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(PRODUCT_LIST)}>
      &lt; Powrót
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const Product = ({ ...props }) => {
  const { id: productId } = useSelector(selectCurrentProduct);
  return (
    <BreadcrumbItem {...props}>
      {productId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(PRODUCT_SHOW, { productId })}
        >
          &lt; Powrót
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const AdFormats = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(AD_FORMAT_LIST)}>
      &lt; Powrót
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const AdFormat = ({ ...props }) => {
  const { id: formatId } = useSelector(selectCurrentAdFormat);
  return (
    <BreadcrumbItem {...props}>
      {formatId ? (
        <BreadcrumbLink
          as={Link}
          to={generatePath(AD_FORMAT_SHOW, { formatId })}
        >
          &lt; Powrót
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const AdSlots = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(AD_SLOT_LIST)}>
      &lt; Powrót
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const AdSlot = ({ ...props }) => {
  const { id: slotId } = useSelector(selectCurrentAdSlot);
  return (
    <BreadcrumbItem {...props}>
      {slotId ? (
        <BreadcrumbLink as={Link} to={generatePath(AD_SLOT_SHOW, { slotId })}>
          &lt; Powrót
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const Users = ({ ...props }) => (
  <BreadcrumbItem {...props}>
    <BreadcrumbLink as={Link} to={generatePath(USER_LIST)}>
      &lt; Powrót
    </BreadcrumbLink>
  </BreadcrumbItem>
);

const User = ({ ...props }) => {
  const { id: userId } = useSelector(selectCurrentUser);
  return (
    <BreadcrumbItem {...props}>
      {userId ? (
        <BreadcrumbLink as={Link} to={generatePath(USER_SHOW, { userId })}>
          &lt; Powrót
        </BreadcrumbLink>
      ) : (
        '...'
      )}
    </BreadcrumbItem>
  );
};

const CurrentPage = ({ children, ...props }) => (
  <BreadcrumbItem {...props} isCurrentPage>
    <BreadcrumbLink>{children}</BreadcrumbLink>
  </BreadcrumbItem>
);
CurrentPage.propTypes = {
  children: PropTypes.node.isRequired,
};

Breadcrumbs.Campaigns = Campaigns;
Breadcrumbs.Campaign = Campaign;
Breadcrumbs.Clients = Clients;
Breadcrumbs.Client = Client;
Breadcrumbs.Creatives = Creatives;
Breadcrumbs.Creative = Creative;
Breadcrumbs.Lineitems = Lineitems;
Breadcrumbs.Lineitem = Lineitem;
Breadcrumbs.Proposals = Proposals;
Breadcrumbs.Proposal = Proposal;
Breadcrumbs.Products = Products;
Breadcrumbs.Product = Product;
Breadcrumbs.AdFormats = AdFormats;
Breadcrumbs.AdFormat = AdFormat;
Breadcrumbs.AdSlots = AdSlots;
Breadcrumbs.AdSlot = AdSlot;
Breadcrumbs.Users = Users;
Breadcrumbs.User = User;
Breadcrumbs.CurrentPage = CurrentPage;

export default Breadcrumbs;
