import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './adfromats.adapter';
import { REDUCER_KEY } from './adformats.consts';
import { AdFortmatParams } from './adformats.types';
import { selectCurrentProposal } from 'store/proposals';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (
  state: RootState,
  id: number,
): AdFortmatParams | null => selectors.selectById(state, id) || null;

export const selectCurrentAdFormatId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllAdFormats = createSelector(
  state => selectors.selectAll(state),
  adformats => adformats || [],
);

export const selectCurrentAdFormat = createSelector(
  selectCurrentAdFormatId,
  selectAllAdFormats,
  (currentId: number, adFormats: AdFortmatParams[]) =>
    adFormats.find(({ id }: { id: number }) => id === currentId),
);

export const selectAdFormatsForCurrentProposal = createSelector(
  selectCurrentProposal,
  selectAllAdFormats,
  (currentProposal, adFormats) =>
    adFormats.filter(
      ({ publisher }) =>
        publisher === currentProposal?.publisher?.id || publisher === null,
    ),
);

export const selectadFormatsAsOptions = createSelector(
  selectAllAdFormats,
  (allAdFormats: AdFortmatParams[]) =>
    allAdFormats.map(({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    })),
);

export const selectAdFormatsForCurrentProposalAsOptions = createSelector(
  selectAdFormatsForCurrentProposal,
  (adFormats: AdFortmatParams[]) =>
    adFormats.map(({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    })),
);

export function selectAdFormat(
  state: RootState,
  adFomratId: number,
): AdFortmatParams | undefined {
  return selectors.selectById(state, adFomratId) || undefined;
}
