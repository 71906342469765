import * as React from 'react';
import { useAppSelector } from 'hooks';
import { FormLayout, FormLayoutProps } from 'layouts';
import { SubmitButton, ResetButton } from 'components';
import { FormSkeleton } from 'components/Skeletons';
import {
  selectFormDataForNewLineitemOfCurrentCampaign,
  create,
} from 'store/lineitems';
import { LineitemForm, LineitemFormFields } from './Lineitem.Form';
import { LineitemOfCurrentCampaignFormStatisticsForecast } from './Lineitem.Form.StatisticsForecast';

export const CreateLineitemForCurrentCampaignForm = ({
  ...props
}: FormLayoutProps): JSX.Element => {
  // TODO: do wymiany na reselector
  const newLineitemFormData = useAppSelector(
    selectFormDataForNewLineitemOfCurrentCampaign,
  );

  if (!newLineitemFormData) return <FormSkeleton />;

  return (
    <LineitemForm
      messageOnSuccess='Lineitem was successfully created'
      dispatchMethod={create}
      defaultValues={newLineitemFormData}
      {...props}
    >
      <FormLayout.Heading title='Create New Lineitem' />
      <FormLayout.Status>
        <LineitemOfCurrentCampaignFormStatisticsForecast />
      </FormLayout.Status>
      <FormLayout.Actions>
        <SubmitButton />
        <ResetButton />
      </FormLayout.Actions>
      <FormLayout.Body>
        <LineitemFormFields />
      </FormLayout.Body>
    </LineitemForm>
  );
};
