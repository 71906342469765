import { getTimezoneOffset } from 'date-fns-tz';
import {
  fromUnixTime,
  getUnixTime,
  subMilliseconds,
  addMilliseconds,
} from 'date-fns';

const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * przelicza czas UTC na relatywny odpowiednik lokalnego czasu
 * np. 00:00:00 1.01.01 UTC  na 03:00:00 1.01.01 GTM +3
 */

export function relativeUtcToLocalTime(date) {
  const offset = getTimezoneOffset(TIMEZONE, date);
  return subMilliseconds(date, offset);
}

/**
 * przelicza lokalny czas na relatywny odpowiednik UTC
 * np. 00:00:00 1.01.01 GMT+3  na 03:00:00 1.01.01 UTC
 */
export function localTimetoRelativeUTC(date) {
  const offset = getTimezoneOffset(TIMEZONE, date);
  const converted = addMilliseconds(date, offset);
  return converted;
}

export function normalizeDateFromApi(timestamp) {
  return relativeUtcToLocalTime(fromUnixTime(timestamp));
}

export function normalizeDateForApi(date) {
  return getUnixTime(localTimetoRelativeUTC(Date.parse(date)));
}

export function parseDateFromApi(date) {
  return relativeUtcToLocalTime(Date.parse(date));
}

export function fromatDateOfCampaign(date) {
  return date.toLocaleDateString('pl-PL', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
}
