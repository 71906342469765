import React from 'react';
import {
  Button,
  ButtonProps,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Link, generatePath, useHistory } from 'react-router-dom';
import {
  CAMPAIGN_LIST,
  CAMPAIGN_CREATE,
  CAMPAIGN_SHOW,
  CAMPAIGN_EDIT,
} from 'routes';
import { withCurrentCampaignId } from 'hoc';
import { useAppSelector } from 'hooks';
import { selectCampaign } from 'store/campaigns';
import { CampaignId } from './Campaign.types';

export function campaignsListPage(): string {
  return generatePath(CAMPAIGN_LIST);
}

export function campaignCreatePage(): string {
  return generatePath(CAMPAIGN_CREATE);
}

export function campaignShowPage(campaignId: number): string {
  return generatePath(CAMPAIGN_SHOW, { campaignId });
}

export function campaignEditPage(campaignId: number): string {
  return generatePath(CAMPAIGN_EDIT, { campaignId });
}

export const CampaignsListLink = ({
  children,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <Button to={campaignsListPage()} as={Link} {...props}>
      {children}
    </Button>
  );
};

export const CampaignsListBreadcrumbLink = (): JSX.Element => {
  return (
    <BreadcrumbItem>
      <BreadcrumbLink to={campaignsListPage()} as={Link}>
        Lista kampanii
      </BreadcrumbLink>
    </BreadcrumbItem>
  );
};

export const ShowCampaignLink = ({
  children,
  ...props
}: CampaignId & ButtonProps): JSX.Element => {
  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };
  return (
    <Button onClick={handleClick} as={Link} {...props}>
      {children}
    </Button>
  );
};

export const ShowCampaignBreadcrumbLink = ({
  campaignId,
}: CampaignId): JSX.Element => {
  const campaign = useAppSelector(state => selectCampaign(state, campaignId));
  return (
    <BreadcrumbItem>
      <BreadcrumbLink to={campaignShowPage(campaignId)} as={Link}>
        {campaign?.name}
      </BreadcrumbLink>
    </BreadcrumbItem>
  );
};

export const ShowCurrentCampaignLink = withCurrentCampaignId(ShowCampaignLink);
export const ShowCurrentCampaignBreadcrumbLink = withCurrentCampaignId(
  ShowCampaignBreadcrumbLink,
);
