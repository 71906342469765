import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { NAME } from './consts.consts';
import {
  generateCreativeTemplateValidationSchema,
  generateCreativeTemplateFieldsets,
} from './consts.utils';

export const fetchAll = createAsyncThunk(
  NAME + '/fetchAll',
  withErrorHandling(async () => {
    const [
      { data: consts },
      { data: productcategories },
      { data: publishers },
      { data: users },
      { data: version },
    ] = await Promise.all([
      adserver({ url: '/consts' }),
      adserver({ url: '/productcategories' }),
      adserver({ url: '/publishers' }),
      adserver({ url: '/consts/users' }),
      adserver({ url: '/version' }),
    ]);
    /**
     * obsługa szablonów kreacji
     * dane przychodzące z api wymagają uporządkowania i przepisania do formy
     * z której będzie wygodnie korzystać przy tworzeniu komponentów
     * reducer jest najlepszym miejscem na taką operację, bo dzieje się ona tylko raz, przy pobieraniu danych
     */
    const templates = consts.templates
      // sortowanie rosnąco po wartości parametru order
      .sort((a, b) => {
        if (!a.order || !b.order) return 0;
        return a.order - b.order;
      })
      .map(template => {
        const { id, label, name, tip, groups = [] } = template;

        // tworzymy grupę dla parametrów, które mają groupId=null
        // dzięki temu korzystamy z mechanizmu grup by umieścić je w jednym fieldsecie
        groups.push({ id: null });

        const validationSchema = generateCreativeTemplateValidationSchema(
          template.parameters,
        );

        const fieldsets = generateCreativeTemplateFieldsets(
          groups,
          template.parameters,
        );

        return {
          id,
          label,
          name,
          tip,
          fieldsets,
          validationSchema,
          defaultValues: validationSchema.getDefault(),
        };
      });

    const categories = productcategories
      .filter(category => category.isLeaf === true)
      .map(category => {
        if (category.parentId) {
          const parent = productcategories.find(
            parent => parent.id === category.parentId,
          );
          return {
            id: category.id,
            name: `${parent.name} > ${category.name}`,
          };
        }
        return {
          id: category.id,
          name: category.name,
        };
      });

    return {
      ...consts,
      version,
      templates,
      productcategories,
      publishers,
      categories,
      users,
    };
  }),
);
