import * as Yup from 'yup';

const READ_ONLY_FIELDS = {
  id: Yup.number().integer().required(),
  campaignId: Yup.number().integer().required(),
};

/**
 * pojedyncza kreacja z listy przypisanej do lineitema
 */
export const CREATIVE_FIELD = Yup.object().shape({
  creativeId: Yup.number().integer().required(),
  isSelected: Yup.bool().required().default(false),
  cpm: Yup.number().required().min(0.01).default(0.01),
  cpc: Yup.number().required().min(0.01).default(0.01),
});

export const EVENT_PLAN_TYPES = ['total', 'daily'];
export const EVENT_PLAN_PACE_TYPES = ['uniform', 'asap', 'ahead'];

export const EDITABLE_FIELDS = {
  general: Yup.object().shape({
    name: Yup.string().required(),
    eventPlan: Yup.object().shape({
      eventPlanType: Yup.mixed()
        .oneOf(EVENT_PLAN_TYPES)
        .default(EVENT_PLAN_TYPES[0])
        .required(),
      paceType: Yup.mixed()
        .oneOf(EVENT_PLAN_PACE_TYPES)
        .default(EVENT_PLAN_PACE_TYPES[0])
        .required(),
      eventCount: Yup.number().integer().required().min(1).default(1000),
      eventType: Yup.string().required().default('clicks'),
    }),
    dailyBudget: Yup.number().required().min(1).default(30),
    minimalCtr: Yup.object().shape({
      isActive: Yup.bool().required().default(false),
      value: Yup.number().required().min(0.01).default(0.1),
    }),
    privateMarketplace: Yup.object().shape({
      isActive: Yup.bool().required().default(false),
      value: Yup.string().default(''),
    }),
    timing: Yup.object().shape({
      startDate: Yup.date().required(),
      endDate: Yup.date().required(),
      dailyTiming: Yup.array(Yup.number().integer())
        .required()
        .min(1)
        .default([...Array(7)].map((_, i) => i)),
      hourlyTiming: Yup.array(Yup.number().integer())
        .required()
        .min(2)
        .max(2)
        .default([0, 24]),
    }),
    frequencyCapping: Yup.object().shape({
      enabled: Yup.bool().required().default(false),
      count: Yup.number().required().min(0).default(1),
      period: Yup.string().default('day'),
    }),
  }),
  targeting: Yup.object().shape({
    operatingSystem: Yup.array(Yup.string())
      .required()
      .default(['android', 'ios']),
    exchange: Yup.array(Yup.string())
      .required()
      .default(['adx', 'smartyads', 'adocean', 'aceex']),
    country: Yup.array(Yup.number()).required().min(1).default([1]),
    trafficSource: Yup.array(Yup.string()).required().default(['site', 'app']),
    city: Yup.object().shape({
      list: Yup.array(Yup.string()).required().default([]),
      exclude: Yup.bool().required().default(false),
    }),
    region: Yup.object().shape({
      list: Yup.array(Yup.string()).required().default([]),
      exclude: Yup.bool().required().default(false),
    }),
    language: Yup.array(Yup.string()).default([]),
    geoTargetingList: Yup.object().shape({
      enabled: Yup.bool().required().default(false),
      precision: Yup.number().required().default(2),
      targeting: Yup.number().integer().nullable(true),
    }),
    deviceBrand: Yup.array(Yup.number().integer()).required().default([]),
    deviceMarketname: Yup.array(Yup.number().integer()).required().default([]),
    sitePlacementTargeting: Yup.object().shape({
      placementList: Yup.array().of(Yup.string().min(1)).required().default([]),
      targetingFile: Yup.string().nullable(true).default(null),
      includePlacement: Yup.bool().required().default(false),
    }),
    appPlacementTargeting: Yup.object().shape({
      placementList: Yup.array().of(Yup.string().min(1)).required().default([]),
      targetingFile: Yup.string().nullable(true).default(null),
      includePlacement: Yup.bool().required().default(false),
    }),
    placementCategoryTargeting: Yup.object().shape({
      placementCategories: Yup.array()
        .of(Yup.string().min(1))
        .required()
        .default([]),
      exclude: Yup.bool().required().default(false),
    }),
    userCategoryTargeting: Yup.object().shape({
      userCategories: Yup.array()
        .of(Yup.string().min(1))
        .required()
        .default([]),
      exclude: Yup.bool().required().default(false),
    }),
    campaignRetargeting: Yup.object().shape({
      campaignIds: Yup.array(Yup.number().integer()).required().default([]),
      targetingType: Yup.string().required().default('clicks'),
    }),
    demographics: Yup.object().shape({
      age: Yup.array().of(Yup.string()).default([]),
      gender: Yup.array().of(Yup.string()).default([]),
    }),
  }),
  targetingsExclude: Yup.array(Yup.number().integer()).required().default([]),
  targetingsInclude: Yup.array(Yup.number().integer()).required().default([]),
  modelsetId: Yup.number().integer(),
  optimizationId: Yup.number().integer(),
  creatives: Yup.array().of(CREATIVE_FIELD).default([]).required().min(1),
  totalBudget: Yup.number().default(0),
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape(EDITABLE_FIELDS);

export const MODEL_VALIDATION_SCHEMA = Yup.object().shape({
  ...READ_ONLY_FIELDS,
  ...EDITABLE_FIELDS,
});
