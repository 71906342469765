import React from 'react';
import PropTypes from 'prop-types';
import { useSelect, useMultipleSelection } from 'downshift';
import FieldContainer from './Select.FieldContainer';
import Field from './Select.Field';
import Options from './Select.Options';
import Tags from './Select.Tags';
import { OPTION_SHAPE, SELECTFIELD_SHARED_PROPS } from './Select.consts';

/**
 * pole z wielokrotną opcją do wyboru
 */
const MultipleOptionsSelectField = ({
  options,
  placeholder,
  size,
  variant,
  selectedIds,
  isDisabled,
  isCleareable,
  isFilterable,
  showUnfilteredResults,
  onChange,
}) => {
  const isItemSelected = checkedItem =>
    !!selectedItems.find(selectedItem => selectedItem?.id === checkedItem?.id);
  const {
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    setSelectedItems,
  } = useMultipleSelection({
    selectedItems: selectedIds
      .map(selectedId => options.find(option => option.id === selectedId))
      .filter(item => item !== undefined),
    onSelectedItemsChange: ({ selectedItems }) =>
      onChange(selectedItems.map(item => item.id)),
    stateReducer: (state, { type, changes, selectedItem }) => {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionAddSelectedItem:
          return {
            ...changes,
            selectedItems: isItemSelected(selectedItem)
              ? selectedItems
              : [...selectedItems, selectedItem],
          };
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          return {
            ...changes,
            selectedItems: selectedItems.filter(
              item => item.id !== selectedItem.id,
            ),
          };
        default:
          return changes; // otherwise business as usual.
      }
    },
  });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0,
    items: options,
    itemToString: item => item?.name || '',
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (isItemSelected(selectedItem)) {
              removeSelectedItem(selectedItem);
            } else {
              addSelectedItem(selectedItem);
            }
          }
          break;
        default:
          break;
      }
    },
  });
  const handleClear = () => setSelectedItems([]);
  const handleRemoveItem = item => removeSelectedItem(item);

  return (
    <FieldContainer size={size} variant={variant}>
      <Field
        isOpen={isOpen}
        isDisabled={isDisabled}
        isCleareable={isCleareable}
        onClear={handleClear}
        {...getToggleButtonProps(
          getDropdownProps({ preventKeyAction: isOpen }),
        )}
      >
        {selectedItems.length ? (
          <Tags items={selectedItems} onRemoveItem={handleRemoveItem} />
        ) : (
          placeholder
        )}
      </Field>
      <Options
        isOpen={isOpen}
        options={options.map((item, index) => ({
          ...item,
          ...getItemProps({ item, index }),
          isSelected: isItemSelected(item),
        }))}
        isFilterable={isFilterable}
        showUnfilteredResults={showUnfilteredResults}
        {...getMenuProps()}
      />
    </FieldContainer>
  );
};

MultipleOptionsSelectField.propTypes = {
  selectedIds: PropTypes.arrayOf(OPTION_SHAPE.id).isRequired,
  ...SELECTFIELD_SHARED_PROPS,
};

MultipleOptionsSelectField.defaultProps = {
  placeholder: 'Wybierz opcje',
  options: [],
  selectedIds: [],
};

export default MultipleOptionsSelectField;
