export const CAN_VIEW_CAMPAIGNS = 'view_campaign';
export const CAN_EDIT_CAMPAIGNS = 'change_campaign';
export const CAN_PREVIEW_CAMPAIGNS = 'preview_campaign';
export const CAN_VIEW_CLIENTS = 'view_client';
export const CAN_EDIT_CLIENTS = 'change_client';
export const CAN_VIEW_HIDDEN_FEATURES = 'view_hidden_features';
export const CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST =
  'view_agencyname_oncampaignlist';
export const CAN_VIEW_EVERY_BUYER_OBJECTS = 'view_every_buyer_objects';
export const CAN_EDIT_EVERY_BUYER_OBJECTS = 'edit_every_buyer_objects';
export const CAN_VIEW_PRODUCTS = 'view_product';
export const CAN_EDIT_PRODUCTS = 'change_product';
export const CAN_VIEW_PROPOSALS = 'view_proposal';
export const CAN_EDIT_PROPOSALS = 'change_proposal';
export const CAN_DELETE_PROPOSALS = 'delete_proposal';
export const CAN_EDIT_USERS = 'change_user';
export const CAN_VIEW_USERS = 'view_user';
export const CAN_VIEW_PRODUCT_METADATA = 'view_product_metadata';
export const CAN_EDIT_PRODUCT_METADATA = 'change_product_metadata';

export const ALL_PERMISSIONS = [
  CAN_VIEW_CAMPAIGNS,
  CAN_EDIT_CAMPAIGNS,
  CAN_PREVIEW_CAMPAIGNS,
  CAN_VIEW_CLIENTS,
  CAN_EDIT_CLIENTS,
  CAN_VIEW_HIDDEN_FEATURES,
  CAN_VIEW_AGENCY_NAME_ON_CAMPAING_LIST,
  CAN_VIEW_EVERY_BUYER_OBJECTS,
  CAN_EDIT_EVERY_BUYER_OBJECTS,
  CAN_VIEW_PRODUCTS,
  CAN_EDIT_PRODUCTS,
  CAN_VIEW_PROPOSALS,
  CAN_EDIT_PROPOSALS,
  CAN_DELETE_PROPOSALS,
  CAN_EDIT_USERS,
  CAN_VIEW_USERS,
  CAN_VIEW_PRODUCT_METADATA,
  CAN_EDIT_PRODUCT_METADATA,
];
