const baseStyle = {
  tablist: {
    marginBottom: 4,
  },
  tabpanel: {
    padding: 0,
  },
};

const sizes = {};

const variants = {
  'solid-rounded': {
    tab: {
      borderRadius: 'md',
      _selected: {
        bg: 'nexusBlue.200',
        color: 'dark.300',
      },
    },
  },
};

const defaultProps = {
  variant: 'solid-rounded',
};

export default {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
