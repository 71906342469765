import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './adslots.adapter';
import { REDUCER_KEY } from './adslots.consts';
import { AdSlotParams } from './adslots.types';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (state: RootState, id: number): AdSlotParams | null =>
  selectors.selectById(state, id) || null;

export const selectCurrentAdSlotId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllAdSlots = createSelector(
  state => selectors.selectAll(state),
  adformats => adformats || [],
);

export const selectCurrentAdSlot = createSelector(
  selectCurrentAdSlotId,
  selectAllAdSlots,
  (currentId: number, adSlots: AdSlotParams[]) =>
    adSlots.find(({ id }: { id: number }) => id === currentId),
);

export const selectadSlotsAsOptions = createSelector(
  selectAllAdSlots,
  (allAdSlots: AdSlotParams[]) =>
    allAdSlots.map(({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    })),
);

export function selectAdSlot(
  state: RootState,
  adSlotId: number,
): AdSlotParams | undefined {
  return selectors.selectById(state, adSlotId) || undefined;
}
