import * as React from 'react';
import { useAppSelector } from 'hooks';
import { selectCurrentCampaignId } from 'store/campaigns';
import { selectCurrentCreativeId } from 'store/creatives';
import { selectCurrentLineitemId } from 'store/lineitems';
import { selectCurrentClientId } from 'store/clients';
import { selectCurrentProposalId } from 'store/proposals';
import { selectCurrentProductId } from 'store/products';
import { selectCurrentUserId } from 'store/users';
import { selectCurrentAdSlotId } from 'store/adslots';
import { selectCurrentAdFormatId } from 'store/adformats';
import { ReactComponent } from 'types';

const SELECTORS: Record<string, any> = {
  campaign: selectCurrentCampaignId,
  creative: selectCurrentCreativeId,
  lineitem: selectCurrentLineitemId,
  client: selectCurrentClientId,
  proposal: selectCurrentProposalId,
  product: selectCurrentProductId,
  user: selectCurrentUserId,
  adSlot: selectCurrentAdSlotId,
  adFormat: selectCurrentAdFormatId,
};

export const withCurrentEntityId = (
  Component: React.ElementType,
  entityName:
    | 'campaign'
    | 'creative'
    | 'lineitem'
    | 'client'
    | 'proposal'
    | 'product'
    | 'user'
    | 'adSlot'
    | 'adFormat',
): React.ElementType => {
  const ComponentWithCurrentEntityId = ({
    children,
    ...props
  }: ReactComponent): JSX.Element | null => {
    const entitySelector = SELECTORS[entityName];
    const entityId = useAppSelector(entitySelector);

    if (!entityId) return null;

    return (
      <Component {...props} {...{ [`${entityName}Id`]: entityId }}>
        {children}
      </Component>
    );
  };
  return ComponentWithCurrentEntityId;
};

export const withCurrentCampaignId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'campaign');

export const withCurrentCreativeId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'creative');

export const withCurrentLineitemId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'lineitem');

export const withCurrentClientId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'client');

export const withCurrentProposalId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'proposal');

export const withCurrentProductId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'product');

export const withCurrentUserId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'user');

export const withCurrentAdSlotId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'adSlot');

export const withCurrentAdFormatId = (
  Component: React.ElementType,
): React.ElementType => withCurrentEntityId(Component, 'adFormat');
