import * as React from 'react';
import { withCurrentLineitemId } from 'hoc';
import { useAppSelector } from 'hooks';
import { FormLayout, FormLayoutProps } from 'layouts';
import { SubmitButton, ResetButton } from 'components';
import { FormSkeleton } from 'components/Skeletons';
import { selectLineitemFormData, update } from 'store/lineitems';
import { LineitemForm, LineitemFormFields } from './Lineitem.Form';
import { LineitemIdProps } from '../Lineitem.types';
import { LineitemOfCurrentCampaignFormStatisticsForecast } from './Lineitem.Form.StatisticsForecast';

export const EditLineitemForm = ({
  lineitemId,
  ...props
}: FormLayoutProps & LineitemIdProps): JSX.Element => {
  const lineitemFormData = useAppSelector(state =>
    selectLineitemFormData(state, lineitemId),
  );

  if (!lineitemFormData) return <FormSkeleton />;

  return (
    <LineitemForm
      messageOnSuccess='All changes was successfully saved'
      dispatchMethod={update}
      defaultValues={lineitemFormData}
      {...props}
    >
      <FormLayout.Heading title='Edit Lineitem' />
      <FormLayout.Status>
        <LineitemOfCurrentCampaignFormStatisticsForecast />
      </FormLayout.Status>
      <FormLayout.Actions>
        <SubmitButton />
        <ResetButton />
      </FormLayout.Actions>
      <FormLayout.Body>
        <LineitemFormFields />
      </FormLayout.Body>
    </LineitemForm>
  );
};

export const EditCurrentLineitemForm = withCurrentLineitemId(EditLineitemForm);
