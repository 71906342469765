import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './users.adapter';
import { REDUCER_KEY } from './users.consts';
import { UserParams } from './users.types';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (state: RootState, id: number): UserParams | null =>
  selectors.selectById(state, id) || null;

export const selectCurrentUserId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllUsers = createSelector(
  state => selectors.selectAll(state),
  advertisers => advertisers || [],
);

export const selectCurrentUser = createSelector(
  selectCurrentUserId,
  selectAllUsers,
  (currentId: number, advertisers: UserParams[]) =>
    advertisers.find(({ id }: { id: number }) => id === currentId),
);

export const selectUserAsOptions = createSelector(
  selectAllUsers,
  (allUsers: UserParams[]) =>
    allUsers.map(
      ({
        id,
        firstName,
        lastName,
      }: {
        id: number;
        firstName: string;
        lastName: string;
      }) => ({
        id,
        name: `${firstName} ${lastName}`,
      }),
    ),
);

export function selectUser(
  state: RootState,
  advertiserId: number,
): UserParams | undefined {
  return selectors.selectById(state, advertiserId) || undefined;
}

export function selectUsersOfBuyer(
  state: RootState,
  buyerId: number,
): UserParams[] {
  return selectors.selectAll(state).filter(({ buyer }) => buyer === buyerId);
}
