import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button,
  IconButton,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from '@chakra-ui/react';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import { FiTrash2 } from 'react-icons/fi';

const TOAST_PROPS = {
  position: 'bottom',
  isClosable: true,
};

/**
 * Komponent UI obsługujący proces usuwania zasobu,
 * przycisk, po kliknięciu pokazuje się alert z promptem
 *
 * @param {*} { action, redirectTo, entityName }
 * @return {*}
 */
const DeleteButton = ({
  action,
  permissions,
  redirectTo,
  entityName,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const hasPermissions = userHasRequiredPermissions(permissions);

  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onClose = () => setIsAlertOpen(false);

  const cancelActionRef = useRef();
  const mountedComponentRef = useRef();

  const handleClick = () => setIsAlertOpen(true);
  const handleDeleteAction = async () => {
    setIsRequestPending(true);
    const response = await dispatch(action);

    if (mountedComponentRef.current) setIsRequestPending(false);
    if ('error' in response) {
      toast({
        ...TOAST_PROPS,
        title: 'Wystąpił błąd',
        description: response.payload.message,
        status: 'error',
      });
    } else {
      toast({
        ...TOAST_PROPS,
        title: 'Sukces',
        description: `${entityName} został usunięty.`,
        status: 'success',
        duration: 3000,
      });
      if (redirectTo) history.push(redirectTo);
    }
  };

  // zmiany w state po usunięciu zasobu mogą spowodować, że komponent przestanie być renderowany
  // poniższy workaround zapobiega wyciekowi pamięci
  useEffect(() => {
    mountedComponentRef.current = true;
    return () => {
      mountedComponentRef.current = false;
    };
  }, []);
  if (!hasPermissions) return null;

  return (
    <>
      <Tooltip label={`Usuń ${entityName}`} hasArrow placement='top'>
        <span>
          <IconButton
            aria-label={`Usuń ${entityName}`}
            icon={<FiTrash2 />}
            onClick={handleClick}
            isDisabled={isDisabled}
          />
        </span>
      </Tooltip>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelActionRef}
        onClose={onClose}
        closeOnOverlayClick={!isRequestPending}
        closeOnEsc={!isRequestPending}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='xl' fontWeight='bold'>
              Usuń {entityName}
            </AlertDialogHeader>

            <AlertDialogBody>
              Czy jesteś pewien? Procesu tego nie można odwrócić.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelActionRef}
                onClick={onClose}
                isDisabled={isRequestPending}
                minWidth='12ch'
              >
                Anuluj
              </Button>
              <Button
                colorScheme='red'
                onClick={handleDeleteAction}
                ml={3}
                isLoading={isRequestPending}
                minWidth='12ch'
                leftIcon={<FiTrash2 />}
              >
                Usuń
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

DeleteButton.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  redirectTo: PropTypes.string,
  action: PropTypes.any.isRequired,
  entityName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default DeleteButton;
