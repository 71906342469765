import * as React from 'react';
import { HistoryEvent } from 'store/history';
import { Table, Thead, Tbody, Tr, Th, TableContainer } from '@chakra-ui/react';
import { Operation } from './History.Operation';

export type HistoryProps = {
  events?: HistoryEvent[];
};

/**
 * Główny komponent: tabela z listą zdarzeń, wraz z opcją pokazania zmian składających się na zdarzenie
 */
export const HistoryTable = ({ events }: HistoryProps): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Operation type</Th>
            <Th>Entity</Th>
            <Th>User</Th>
            <Th>Date</Th>
            <Th isNumeric>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {events?.map(event => (
            <Operation key={event.id} {...event} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
