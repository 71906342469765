import { mode } from '@chakra-ui/theme-tools';

export default {
  sizes: {
    md: {
      field: {
        fontSize: 'sm',
        borderRadius: 'sm',
        h: 8,
        px: 3,
      },
      element: {
        fontSize: 'sm',
        h: 8,
        px: 0.5,
      },
    },
  },
  variants: {
    outline: props => ({
      field: {
        background: mode('white.100', 'dark.100')(props),
        color: mode('graphite.500', 'dark.300')(props),
        borderColor: 'graphite.300',
        borderWidth: '1px',
        _hover: {
          borderColor: 'graphite.500',
        },
        _focus: {
          borderColor: 'graphite.500',
          boxShadow: 'unset',
        },
      },
      dropdown: {
        background: mode('white.100', 'dark.100')(props),
        color: mode('graphite.500', 'dark.300')(props),
      },
    }),
    flushed: props => ({
      field: {
        background: mode('white.100', 'dark.100')(props),
        color: mode('graphite.500', 'dark.300')(props),
        borderColor: 'yellow.300',
        borderWidth: '1px',
        _hover: {
          borderColor: 'yellow.500',
        },
        _focus: {
          borderColor: 'tellow.500',
          boxShadow: 'unset',
        },
      },
      dropdown: {
        background: mode('white.100', 'dark.100')(props),
        color: mode('graphite.500', 'dark.300')(props),
      },
    }),
  },
};
