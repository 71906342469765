import * as React from 'react';
import { startOfToday } from 'date-fns';
import { ControlledInputDate } from 'components';

const STARTDATE = 'startDate';

export const CampaignStartDate = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  return (
    <ControlledInputDate
      label='Data startu'
      name={STARTDATE}
      type='date'
      min={startOfToday()}
      {...props}
    />
  );
};
