import * as React from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputProps,
  InputGroup,
  Input,
  StylesProvider,
  useMultiStyleConfig,
  NumberInputStepperProps,
  InputLeftElement,
} from '@chakra-ui/react';
import { fomratNumberSpace } from 'helpers';

interface InputNumberProps {
  addon?: string;
  isDisabled?: boolean;
  brColor?: string;
}

const NumberInputGroupStepper = (props: NumberInputStepperProps) => {
  const numberInputStyles = useMultiStyleConfig('NumberInput', props); // maybe {} instead of props would be better?
  return (
    <StylesProvider value={numberInputStyles}>
      <NumberInputStepper {...props} />
    </StylesProvider>
  );
};

export const InputNumber = ({
  addon,
  isDisabled,
  brColor,
  ...props
}: NumberInputProps & InputNumberProps): JSX.Element => {
  const handleCustomFormat = (value: string | number) => {
    return fomratNumberSpace(value);
  };

  return (
    <NumberInput
      as={InputGroup}
      isDisabled={isDisabled}
      format={handleCustomFormat}
      pattern='([0-9]+( [0-9]+)*)'
      {...props}
    >
      {addon && <InputLeftElement>{addon}</InputLeftElement>}
      <Input
        as={NumberInputField}
        isDisabled={isDisabled}
        borderColor={brColor}
      />
      <NumberInputGroupStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputGroupStepper>
    </NumberInput>
  );
};
