/* eslint-disable no-unreachable */
import axios from 'axios';

const authBaseConfig = {
  baseURL: process.env.REACT_APP_ADSERVER_ENDPOINT || '',
};

class AuthService {
  login({ username, password }) {
    return axios({
      ...authBaseConfig,
      method: 'post',
      url: '/user/login',
      data: { login: username, password },
    });
  }

  loginOcta({ oktaToken }) {
    return axios({
      ...authBaseConfig,
      method: 'post',
      url: '/user/okta_login',
      data: { oktaToken: oktaToken },
    });
  }

  logout({ token }) {
    return axios({
      ...authBaseConfig,
      headers: {
        Authorization: `Token ${token}`,
      },
      method: 'get',
      url: '/user/logout',
    });
  }

  userProfile({ token }) {
    return axios({
      ...authBaseConfig,
      headers: {
        Authorization: `Token ${token}`,
      },
      url: '/user/me',
    });
  }
}

export default new AuthService();
