import React from 'react';
import { ModalInfo } from 'components';
import { Text, ModalHeader, ModalBody, Grid } from '@chakra-ui/react';
import { ProdudctValueParams } from 'store/products';
import { Fieldset } from 'components';
import {
  SingleField,
  MultiOptionField,
  FileField,
} from 'components/ModalInfo.Fields';
import {
  ProductCategoryInfo,
  ProductAdSlotsInfo,
  ProductAdFormatInfo,
} from 'components/Product/infoFields';
import { Link } from 'react-router-dom';

export const ShowProductModalInfo = ({
  text,
  product,
}: {
  text: string;
  product: ProdudctValueParams;
}): JSX.Element => {
  return (
    <ModalInfo
      trigger={
        <Link to='#'>
          <Text variant='link' as='span'>
            {text}
          </Text>
        </Link>
      }
      info={<ProductModalInfo {...product} />}
    />
  );
};

export const ProductModalInfo = (product: ProdudctValueParams): JSX.Element => {
  const value = product || {};

  return (
    <>
      <ModalHeader mb={4}>Product info</ModalHeader>

      <ModalBody>
        <Grid gap={6} templateColumns='repeat(3,1fr)'>
          <Fieldset gridColumn='span 3'>
            <SingleField
              header='Nazwa produktu'
              value={value.name}
              gridColumn={{ base: 'span 12', md: 'span 12' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <SingleField
              header='Rodzaj emisji'
              value={value.adType}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <SingleField
              header='Model rozliczeniowy'
              value={value.pricingModel}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <MultiOptionField
              header='Miejsce emisji'
              elements={value.emissionModels}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <SingleField
              header='Serwisy/Aplikacje'
              value={value.services}
              gridColumn={{ base: 'span 12', md: 'span 12' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <ProductCategoryInfo
              value={value.categoryIds}
              gridColumn={{ base: 'span 12', md: 'span 12' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <ProductAdSlotsInfo
              value={value.adSlotIds}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <ProductAdFormatInfo
              value={value.adFormatIds}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <SingleField
              header='Platforma emisji'
              value={value.emissionPlatform}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <SingleField
              header='Odsłony'
              value={value.impressions}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <SingleField
              header='Kliknięcia'
              value={value.clicks}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <SingleField
              header='CTR'
              value={`${value.ctr}%`}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <SingleField
              header='Unikalni użytkownicy'
              value={value.uniqueUsers}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <SingleField
              header='Viewability'
              value={value.viewability}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
            <SingleField
              header='Stawka RC'
              value={`${value.price} zł`}
              gridColumn={{ base: 'span 4', md: 'span 4' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <FileField
              header='Wizualizacja'
              value={value.visualSpec}
              gridColumn={{ base: 'span 6', md: 'span 6' }}
            />
            <FileField
              header='Specyfikacja techniczna'
              value={value.technicalSpec}
              gridColumn={{ base: 'span 6', md: 'span 6' }}
            />
          </Fieldset>
          <Fieldset gridColumn='span 3'>
            <SingleField
              header='Targetowanie / Dopłaty'
              value={value.commentary}
              gridColumn={{ base: 'span 12', md: 'span 12' }}
            />
          </Fieldset>
        </Grid>
      </ModalBody>
    </>
  );
};
