import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './products.adapter';
import { REDUCER_KEY } from './products.consts';
import { ProductParams } from './products.types';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (
  state: RootState,
  id: number,
): ProductParams | null => selectors.selectById(state, id) || null;

export const selectCurrentProductId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllProducts = createSelector(
  state => selectors.selectAll(state),
  products => products || [],
);

export const selectAllProductsAsOptions = createSelector(
  state => selectAllProducts(state),
  products =>
    products.map(({ id, value: { name } }) => ({
      id,
      name,
    })),
);

export const selectCurrentProduct = createSelector(
  state => selectCurrentProductId(state),
  state => selectAll(state),
  (currentProductId, allProducts) =>
    allProducts.find(({ id }) => id === currentProductId),
);

export function selectProduct(
  state: RootState,
  productId: number,
): ProductParams | undefined {
  return selectors.selectById(state, productId) || undefined;
}

export function selectSelectedIds(state: RootState): number[] {
  return state[REDUCER_KEY].selected.map(({ id }: { id: number }) => id);
}

export function selectSelectedProducts(state: RootState): ProductParams[] {
  return state[REDUCER_KEY].selected;
}
