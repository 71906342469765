import React from 'react';
import { Tag } from '@chakra-ui/react';

export const MarketAdType = ({ type }: { type: string }): JSX.Element => {
  let color;
  if (!type) return <></>;
  switch (type) {
    case 'Display':
      color = 'display';
      break;
    case 'Video':
      color = 'video';
      break;
    case 'Audio':
      color = 'nexusElectricBeige';
      break;
  }

  return (
    <Tag size='lg' variant={color}>
      {type}
    </Tag>
  );
};
