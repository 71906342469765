export default {
  sizes: {
    sm: {
      container: {
        borderRadius: 'sm',
      },
    },
  },
  variants: {
    video: {
      container: {
        bg: 'nexusLightTeal.100',
        color: 'black',
      },
    },
    display: {
      container: {
        bg: 'nexusElectricBeige.100',
        color: 'black',
      },
    },
  },
};
