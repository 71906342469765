import { ALL_PERMISSIONS } from 'consts/permissions';
import { createSelector } from 'reselect';
import { NAME } from './user.consts';

export const userSelector = state => state[NAME];
export const selectDefaultCampaignMargin = state =>
  state.user.campaignMargin.defaultValue;

export const selectUserPermissions = createSelector(
  state => state[NAME],
  user => ALL_PERMISSIONS.filter(permission => user[permission] === true) || [],
);

export const isUserLoggedIn = createSelector(
  state => state[NAME],
  user => user.isLoggedIn === true,
);

export const selectUserBuyer = createSelector(
  state => state[NAME],
  user => user.buyer,
);

export const selectUserPublisher = createSelector(
  state => state[NAME],
  user => user.publisher,
);

export const isUserBuyer = createSelector(
  state => state[NAME],
  user => typeof user.buyer?.id === 'number',
);
