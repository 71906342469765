import card from './card';

const baseStyle = props => ({
  ...card.baseStyle(props),
});

export default {
  baseStyle,
  sizes: card.sizes,
  variants: {
    ghost: {
      padding: 0,
      background: 'transparent',
    },
  },
  defaultProps: card.defaultProps,
};
