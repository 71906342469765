import React from 'react';
import PropTypes from 'prop-types';
import { Table, Thead, Tr, Tbody, Td, Th, Box } from '@chakra-ui/react';

const TableComponent = ({ children }) => (
  <Table
    variant='main'
  >
    {children}
  </Table>
);

TableComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const Header = ({ children }) => (
  <Thead>
    <Tr>{children}</Tr>
  </Thead>
);
Header.propTypes = {
  children: PropTypes.node.isRequired,
};

const Body = ({ children }) => <Tbody >{children}</Tbody>;
Body.propTypes = {
  children: PropTypes.node.isRequired,
};

const Row = ({ children }) => <Tr >{children}</Tr>;
Row.propTypes = {
  children: PropTypes.node.isRequired,
};

const ALIGNS = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const Cell = ({
  valign: horizontal = 'center',
  align: vertical = 'left',
  children,
}) => (
  <Td>
    <Box
      display='flex'
      w='100%'
      h='100%'
      justifyContent={ALIGNS[vertical]}
      alignItems={ALIGNS[horizontal]}
    >
      {children}
    </Box>
  </Td>
);
Cell.propTypes = {
  align: PropTypes.string,
  valign: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const Column = ({ shrink = false, align = 'left', children, ...props }) => (
  <Th
    // borderColor= 'graphite.500'
    textAlign={align}
    width={shrink ? '1%' : 'auto'}
    whiteSpace={shrink ? 'nowrap' : 'normal'}
    {...props}
  >
    {children}
  </Th>
);
Column.propTypes = {
  align: PropTypes.string,
  shrink: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

TableComponent.Header = Header;
TableComponent.Column = Column;
TableComponent.Body = Body;
TableComponent.Row = Row;
TableComponent.Cell = Cell;

export default TableComponent;
