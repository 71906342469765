import { createSelector } from 'reselect';
import { RootState } from 'store';
import { NAME } from './consts.consts';
import { CreativeTemplateType, ProductCategoryType } from './consts.types';

export type OptionsArray = Array<{
  id: number | string;
  name: string;
}>;

export type UserRoleType = Array<{
  id: string;
  name: string;
  hasAssignedClients: boolean;
}>;

const selectConstsState = (state: RootState) => state[NAME];

// pola kampanii
export const selectIabCategories = (state: RootState): OptionsArray =>
  state[NAME].iabCategories;
export const selectRestrictedCategories = (state: RootState): OptionsArray =>
  state[NAME].restrictedCategories;

// pola kreacji
export const selectAdType = (state: RootState): OptionsArray =>
  state[NAME].adTypes;
export const selectCreativeFormats = (state: RootState): OptionsArray =>
  state[NAME].creativeFormats;
export const selectCreativeAttributes = (state: RootState): OptionsArray =>
  state[NAME].creativeAttributes;
export const selectGoogleAttributes = (state: RootState): OptionsArray =>
  state[NAME].googleAttributes;
export const selectCreativeTemplateName = (state: RootState): OptionsArray =>
  state[NAME].templates;

// pola linetema
export const selectApps = (state: RootState): OptionsArray => state[NAME].apps;
export const selectBiddingModels = (state: RootState): OptionsArray =>
  state[NAME].biddingModels;
export const selectCities = (state: RootState): OptionsArray =>
  state[NAME].cities;
export const selectCountries = (state: RootState): OptionsArray =>
  state[NAME].countries;
export const selectDomains = (state: RootState): OptionsArray =>
  state[NAME].domains;
export const selectEventTypes = (state: RootState): OptionsArray =>
  state[NAME].eventTypes;
export const selectExchanges = (state: RootState): OptionsArray =>
  state[NAME].exchanges;
export const selectFrequencyCappingPeriods = (state: RootState): OptionsArray =>
  state[NAME].frequencyCappingPeriod;
export const selectGeoTargetingPrecisions = (state: RootState): OptionsArray =>
  state[NAME].geoTargetingPrecision;
export const selectLanguages = (state: RootState): OptionsArray =>
  state[NAME].languages;
export const selectMimeTypes = (state: RootState): OptionsArray =>
  state[NAME].mimeTypes;
export const selectOperatingSystems = (state: RootState): OptionsArray =>
  state[NAME].operatingSystems;
export const selectProvinces = (state: RootState): OptionsArray =>
  state[NAME].provinces;
export const selectTargetingLanguages = (state: RootState): OptionsArray =>
  state[NAME].targetingLanguages;
export const selectTargetingListTypes = (state: RootState): OptionsArray =>
  state[NAME].targetingListTypes;
export const selectTrafficSources = (state: RootState): OptionsArray =>
  state[NAME].trafficSource;
export const selectUserCategories = (state: RootState): OptionsArray =>
  state[NAME].userCategories;
export const selectWeekdays = (state: RootState): OptionsArray =>
  state[NAME].days;
export const selectRetargetingTypes = (state: RootState): OptionsArray =>
  state[NAME].retargetingTypes;
export const selectAgeDemographics = (state: RootState): OptionsArray =>
  state[NAME].ageDemographics;
export const selectGenderDemographics = (state: RootState): OptionsArray =>
  state[NAME].genderDemographics;
export const selectPlacementCategories = (state: RootState): OptionsArray =>
  state[NAME].placementCategories;
export const selectVideoCreativeFormats = (state: RootState): OptionsArray =>
  state[NAME].videoCreativeFormats;
export const selectVideoPlacementTypes = (state: RootState): OptionsArray =>
  state[NAME].videoPlacementTypes;
export const selectVideoPlaybackMethodTypes = (
  state: RootState,
): OptionsArray => state[NAME].videoPlaybackMethodTypes;
export const selectVideoStartDelayTypes = (state: RootState): OptionsArray =>
  state[NAME].videoStartDelayTypes;

export const selectCreativeTemplates = createSelector(
  selectConstsState,
  consts => consts.templates,
);

export const selectCreativeTemplate = (
  state: RootState,
  creativeTemplateId: number,
): CreativeTemplateType | undefined => {
  const creativeTemplates = selectCreativeTemplates(state);

  return creativeTemplates?.find(
    (creativeTemplate: CreativeTemplateType) =>
      creativeTemplate.id === creativeTemplateId,
  );
};

export const selectCategories = (state: RootState): OptionsArray =>
  state[NAME].categories || [];

export const selectAdFormats = (state: RootState): OptionsArray =>
  state[NAME].adFormats;

export const selectAdTypes = (state: RootState): OptionsArray =>
  state[NAME].adTypes;

export const selectPricingModels = (state: RootState): OptionsArray =>
  state[NAME].pricingModels;

export const selectEmissionModels = (state: RootState): OptionsArray =>
  state[NAME].emissionModels;

export const selectEmissionPlatforms = (state: RootState): OptionsArray =>
  state[NAME].emissionPlatforms;

export const selectAdSlots = (state: RootState): OptionsArray =>
  state[NAME].adSlots;

export const selectMediaAgencies = (state: RootState): OptionsArray =>
  state[NAME].mediaAgencies;

export const selectPublishers = (state: RootState): OptionsArray =>
  state[NAME].publishers;

export const selectUserRoles = (state: RootState): UserRoleType =>
  state[NAME].users.userRoles;

export const selectProductCategories = (
  state: RootState,
): ProductCategoryType[] => state[NAME].productcategories;

export const selectUserRolesAsOptions = createSelector(
  selectUserRoles,
  userRoles => {
    return userRoles.map(({ id, name }) => ({
      id,
      name,
    }));
  },
);

export const selectUserRolesWithAssignedClients = createSelector(
  selectUserRoles,
  userRoles => {
    const roles = userRoles.filter(
      ({ hasAssignedClients }) => hasAssignedClients === true,
    );
    return roles.map(({ id }) => id);
  },
);

export const selectParentCategories = createSelector(
  state => selectProductCategories(state),
  productCategories => {
    return productCategories?.filter(({ parentId }) => parentId === null) || [];
  },
);

export const selectParentCategoriesIds = createSelector(
  selectParentCategories,
  parentCategories => {
    return parentCategories?.map(({ id }) => id) || [];
  },
);

export const selectParentProductCategoriesWithChildrenIds = createSelector(
  selectParentCategories,
  parentCategories => {
    return (
      parentCategories
        ?.filter(({ isLeaf }) => isLeaf === false)
        .map(({ id }) => id) || []
    );
  },
);

export const selectProductCategorieById = (
  state: RootState,
  id: number,
): ProductCategoryType | undefined => {
  const productCategories = selectProductCategories(state);
  return productCategories?.find(category => category.id === id);
};

export const selectProductCategorieChildrenById = (
  state: RootState,
  id: number,
): ProductCategoryType[] => {
  const productCategories = selectProductCategories(state);
  return productCategories?.filter(category => category.parentId === id) || [];
};

export const selectProductCategorieChildrenNamesById = (
  state: RootState,
  id: number,
): string[] => {
  const productCategories = selectProductCategorieChildrenById(state, id);
  return productCategories?.map(({ name }) => name) || [];
};

export const selectSumOfProducts = createSelector(
  selectParentCategories,
  parentCategories => {
    return (
      parentCategories?.reduce((acc, category) => {
        return acc + category.products;
      }, 0) || 0
    );
  },
);

export const selectMediaAgencyNameById = (
  state: RootState,
  id: number,
): string => {
  const mediaAgencies = selectMediaAgencies(state);
  const mediaAgency = mediaAgencies.find(mediaAgency => mediaAgency.id === id);
  return mediaAgency?.name || '';
};
