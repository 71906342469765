import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { startOfToday, max } from 'date-fns';
import { ControlledInputDate } from 'components';

export const CampaignEndDate = ({
  ...props
}: {
  onChange?: (value: any) => void;
  [key: string]: any;
}): JSX.Element => {
  const { control } = useFormContext();
  const {
    field: { value: startDateValue },
  } = useController({
    name: 'startDate',
    control,
  });

  let minDate;

  if (startDateValue instanceof Date) {
    minDate = max([startOfToday(), startDateValue]);
  }

  return (
    <ControlledInputDate
      label='Data zakończenia'
      name='endDate'
      type='date'
      min={minDate}
      {...props}
    />
  );
};
