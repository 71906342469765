import React from 'react';
import { Switch, Redirect, Route, useHistory } from 'react-router-dom';
import PrivateRoute from 'components/Router.PrivateRoute';
import {
  DASHBOARD,
  LOGIN,
  CAMPAIGN_LIST,
  CAMPAIGN_CREATE,
  CAMPAIGN_SHOW,
  CAMPAIGN_STATISTICS_APPS,
  CAMPAIGN_STATISTICS_WEBSITES,
  CAMPAIGN_EDIT,
  CLIENT_LIST,
  CLIENT_CREATE,
  CLIENT_EDIT,
  CREATIVE_LIST,
  CREATIVE_SHOW,
  CREATIVE_CREATE,
  CREATIVE_EDIT,
  LINEITEM_LIST,
  LINEITEM_SHOW,
  LINEITEM_CREATE,
  LINEITEM_EDIT,
  PROPOSAL_LIST,
  PROPOSAL_SHOW,
  PROPOSAL_EDIT,
  PROPOSAL_CREATE,
  PRODUCT_LIST,
  PRODUCT_CREATE,
  PRODUCT_SHOW,
  PRODUCT_EDIT,
  MARKET_LIST,
  PROPOSAL_NEGOTIATION,
  USER_CREATE,
  USER_EDIT,
  USER_LIST,
  USER_SHOW,
  AD_FORMAT_LIST,
  AD_FORMAT_CREATE,
  AD_FORMAT_EDIT,
  AD_SLOT_LIST,
  AD_SLOT_CREATE,
  AD_SLOT_EDIT,
  MARKET_SHOW,
  PROPOSAL_CREATE_FROM_CAMPAIGN,
  LOGIN_CALLBACK,
} from 'routes';
import {
  CAN_VIEW_CAMPAIGNS,
  CAN_EDIT_CAMPAIGNS,
  CAN_VIEW_CLIENTS,
  CAN_EDIT_CLIENTS,
  CAN_VIEW_PROPOSALS,
  CAN_EDIT_PRODUCTS,
  CAN_VIEW_USERS,
  CAN_EDIT_USERS,
  CAN_EDIT_PRODUCT_METADATA,
} from 'consts/permissions';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

const KitchenSink = React.lazy(() => import('pages/KitchenSink'));
const Login = React.lazy(() => import('pages/Login'));

const CampaignList = React.lazy(() => import('pages/Campaign/Campaign.List'));
const CampaignShow = React.lazy(() => import('pages/Campaign/Campaign.Show'));
const CampaignCreate = React.lazy(() =>
  import('pages/Campaign/Campaign.Create'),
);
const CampaignEdit = React.lazy(() => import('pages/Campaign/Campaign.Edit'));
const CampaignAppsStatistics = React.lazy(() =>
  import('pages/Campaign/statistics/Campaign.Statistics.Apps'),
);
const CampaignWebsitesStatistics = React.lazy(() =>
  import('pages/Campaign/statistics/Campaign.Statistics.Websites'),
);

const ClientList = React.lazy(() => import('pages/Client/Client.List'));
const ClientCreate = React.lazy(() => import('pages/Client/Client.Create'));
const ClientEdit = React.lazy(() => import('pages/Client/Client.Edit'));
// const ClientShow = React.lazy(() => import('pages/Client/Client.Show'));

const CreativeShow = React.lazy(() => import('pages/Creative/Creative.Show'));
const CreativeCreate = React.lazy(() =>
  import('pages/Creative/Creative.Create'),
);
const CreativeEdit = React.lazy(() => import('pages/Creative/Creative.Edit'));

const LineitemShow = React.lazy(() => import('pages/Lineitem/Lineitem.Show'));
const LineitemCreate = React.lazy(() =>
  import('pages/Lineitem/Lineitem.Create'),
);
const LineitemEdit = React.lazy(() => import('pages/Lineitem/Lineitem.Edit'));

const ProposalList = React.lazy(() => import('pages/Proposal/Proposal.List'));
const ProposalShow = React.lazy(() => import('pages/Proposal/Proposal.Show'));
const ProposalEdit = React.lazy(() => import('pages/Proposal/Proposal.Edit'));
const ProposalNegotiation = React.lazy(() =>
  import('pages/Proposal/Proposal.Negotiation'),
);
const ProposalCreate = React.lazy(() =>
  import('pages/Proposal/Proposal.Create'),
);

const ProductList = React.lazy(() => import('pages/Product/Product.List'));
const ProductShow = React.lazy(() => import('pages/Product/Product.Show'));
const ProductCreate = React.lazy(() => import('pages/Product/Product.Create'));
const ProductEdit = React.lazy(() => import('pages/Product/Product.Edit'));

const MarketList = React.lazy(() => import('pages/Market/Market.List'));

const UserList = React.lazy(() => import('pages/User/User.List'));
const UserShow = React.lazy(() => import('pages/User/User.Show'));
const UserCreate = React.lazy(() => import('pages/User/User.Create'));
const UserEdit = React.lazy(() => import('pages/User/User.Edit'));

const AdFormatList = React.lazy(() => import('pages/AdFormat/AdFormat.List'));
const AdFormatCreate = React.lazy(() =>
  import('pages/AdFormat/AdFormat.Create'),
);
const AdFormatEdit = React.lazy(() => import('pages/AdFormat/AdFormat.Edit'));

const AdSlotList = React.lazy(() => import('pages/AdSlot/AdSlot.List'));
const AdSlotCreate = React.lazy(() => import('pages/AdSlot/AdSlot.Create'));
const AdSlotEdit = React.lazy(() => import('pages/AdSlot/AdSlot.Edit'));

const Router = () => {
  const hasPermissions = userHasRequiredPermissions([CAN_VIEW_CAMPAIGNS]);
  const history = useHistory();
  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
  });
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path={LOGIN} exact component={Login} />
        <Route path={LOGIN_CALLBACK} exact component={LoginCallback} />

        <Route path='/kitchensink' exact component={KitchenSink} />

        {hasPermissions ? (
          <Redirect from={DASHBOARD} exact to={MARKET_LIST} />
        ) : (
          <Redirect from={DASHBOARD} exact to={PROPOSAL_LIST} />
        )}
        {/* kampanie */}
        <PrivateRoute
          path={CAMPAIGN_LIST}
          exact
          component={CampaignList}
          permissions={[CAN_VIEW_CAMPAIGNS]}
        />
        <PrivateRoute
          path={CAMPAIGN_CREATE}
          exact
          component={CampaignCreate}
          permissions={[CAN_EDIT_CAMPAIGNS]}
        />
        <PrivateRoute
          path={CAMPAIGN_SHOW}
          exact
          component={CampaignShow}
          permissions={[CAN_VIEW_CAMPAIGNS]}
        />
        <PrivateRoute
          path={CAMPAIGN_EDIT}
          component={CampaignEdit}
          permissions={[CAN_VIEW_CAMPAIGNS]}
        />
        <PrivateRoute
          path={CAMPAIGN_STATISTICS_APPS}
          component={CampaignAppsStatistics}
          permissions={[CAN_VIEW_CAMPAIGNS]}
        />
        <PrivateRoute
          path={CAMPAIGN_STATISTICS_WEBSITES}
          component={CampaignWebsitesStatistics}
          permissions={[CAN_VIEW_CAMPAIGNS]}
        />

        {/* reklamodawcy */}
        <PrivateRoute
          path={CLIENT_LIST}
          exact
          component={ClientList}
          permissions={[CAN_VIEW_CLIENTS]}
        />
        <PrivateRoute
          path={CLIENT_CREATE}
          exact
          component={ClientCreate}
          permissions={[CAN_EDIT_CLIENTS]}
        />
        <PrivateRoute
          path={CLIENT_EDIT}
          component={ClientEdit}
          permissions={[CAN_VIEW_CLIENTS]}
        />
        {/* <PrivateRoute
        path={CLIENT_SHOW}
        component={ClientShow}
        permissions={[CAN_VIEW_CLIENTS]}
      /> */}

        {/* kreacje */}
        <Redirect from={CREATIVE_LIST} exact to={CAMPAIGN_SHOW} />
        <PrivateRoute
          path={CREATIVE_CREATE}
          exact
          component={CreativeCreate}
          permissions={[CAN_EDIT_CAMPAIGNS]}
        />

        <PrivateRoute
          path={CREATIVE_SHOW}
          exact
          component={CreativeShow}
          permissions={[CAN_VIEW_CAMPAIGNS]}
        />
        <PrivateRoute
          path={CREATIVE_EDIT}
          component={CreativeEdit}
          permissions={[CAN_VIEW_CAMPAIGNS]}
        />
        {/* lineitemy */}
        <Redirect from={LINEITEM_LIST} exact to={CAMPAIGN_SHOW} />
        <PrivateRoute
          path={LINEITEM_CREATE}
          component={LineitemCreate}
          permissions={[CAN_EDIT_CAMPAIGNS]}
        />
        <PrivateRoute
          path={LINEITEM_SHOW}
          exact
          component={LineitemShow}
          permissions={[CAN_VIEW_CAMPAIGNS]}
        />
        <PrivateRoute
          path={LINEITEM_EDIT}
          component={LineitemEdit}
          permissions={[CAN_VIEW_CAMPAIGNS]}
        />
        {/* proposals */}
        <PrivateRoute
          path={PROPOSAL_LIST}
          exact
          component={ProposalList}
          permissions={[CAN_VIEW_PROPOSALS]}
        />
        <PrivateRoute
          path={PROPOSAL_SHOW}
          component={ProposalShow}
          exact
          permissions={[CAN_VIEW_PROPOSALS]}
        />
        <PrivateRoute
          path={PROPOSAL_EDIT}
          component={ProposalEdit}
          exact
          permissions={[CAN_VIEW_PROPOSALS]}
        />
        <PrivateRoute
          path={PROPOSAL_NEGOTIATION}
          component={ProposalNegotiation}
          exact
          permissions={[CAN_VIEW_PROPOSALS]}
        />
        <PrivateRoute
          path={PROPOSAL_CREATE}
          component={ProposalCreate}
          exact
          permissions={[CAN_EDIT_CAMPAIGNS]}
        />
        <PrivateRoute
          path={PROPOSAL_CREATE_FROM_CAMPAIGN}
          component={ProposalCreate}
          permissions={[CAN_EDIT_CAMPAIGNS]}
        />
        {/* products */}
        <PrivateRoute
          path={PRODUCT_LIST}
          permissions={[CAN_EDIT_PRODUCTS]}
          exact
          component={ProductList}
        />
        <PrivateRoute
          path={PRODUCT_CREATE}
          permissions={[CAN_EDIT_PRODUCTS]}
          exact
          component={ProductCreate}
        />
        <PrivateRoute
          path={PRODUCT_SHOW}
          permissions={[CAN_EDIT_PRODUCTS]}
          exact
          component={ProductShow}
        />
        <PrivateRoute
          path={PRODUCT_EDIT}
          permissions={[CAN_EDIT_PRODUCTS]}
          component={ProductEdit}
        />
        {/* market */}
        <PrivateRoute
          path={MARKET_SHOW}
          permissions={[CAN_EDIT_CAMPAIGNS]}
          component={MarketList}
        />
        {/* users */}
        <PrivateRoute
          path={USER_LIST}
          permissions={[CAN_VIEW_USERS]}
          exact
          component={UserList}
        />
        <PrivateRoute
          path={USER_CREATE}
          permissions={[CAN_EDIT_USERS]}
          exact
          component={UserCreate}
        />
        <PrivateRoute
          path={USER_SHOW}
          permissions={[CAN_VIEW_USERS]}
          exact
          component={UserShow}
        />
        <PrivateRoute
          path={USER_EDIT}
          permissions={[CAN_EDIT_USERS]}
          component={UserEdit}
        />
        {/* ad formats */}
        <PrivateRoute
          path={AD_FORMAT_LIST}
          permissions={[CAN_EDIT_PRODUCT_METADATA]}
          component={AdFormatList}
        />
        <PrivateRoute
          path={AD_FORMAT_CREATE}
          permissions={[CAN_EDIT_PRODUCT_METADATA]}
          component={AdFormatCreate}
        />
        <PrivateRoute
          path={AD_FORMAT_EDIT}
          permissions={[CAN_EDIT_PRODUCT_METADATA]}
          component={AdFormatEdit}
        />
        {/* ad slots */}
        <PrivateRoute
          path={AD_SLOT_LIST}
          permissions={[CAN_EDIT_PRODUCT_METADATA]}
          component={AdSlotList}
        />
        <PrivateRoute
          path={AD_SLOT_CREATE}
          permissions={[CAN_EDIT_PRODUCT_METADATA]}
          component={AdSlotCreate}
        />
        <PrivateRoute
          path={AD_SLOT_EDIT}
          permissions={[CAN_EDIT_PRODUCT_METADATA]}
          component={AdSlotEdit}
        />
      </Switch>
    </Security>
  );
};

export default Router;
