import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './clients.adapter';
import { REDUCER_KEY } from './clients.consts';
import { ClientParams } from './clients.types';
import {
  selectAllCampaigns,
  selectById as selectCampaignById,
} from 'store/campaigns';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (state: RootState, id: number): ClientParams | null =>
  selectors.selectById(state, id) || null;

export const selectCurrentClientId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllClients = createSelector(
  state => selectors.selectAll(state),
  clients => clients || [],
);

export const selectCurrentClient = createSelector(
  selectCurrentClientId,
  selectAllClients,
  (currentId: number, clients: ClientParams[]) =>
    clients.find(({ id }: { id: number }) => id === currentId),
);

export const selectClientAsOptions = createSelector(
  selectAllClients,
  (allClients: ClientParams[]) =>
    allClients.map(({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    })),
);

export const selectClientAsOptionsOfCampaign = (
  state: RootState,
  campaignId: number,
): { id: number; name: string }[] => {
  const allClients = selectAll(state);
  const campaign = selectCampaignById(state, campaignId);

  const clientsOfCampaign = allClients?.filter(
    client => client?.id === campaign?.id,
  );
  return clientsOfCampaign.map(
    ({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    }),
  );
};

export function selectClient(
  state: RootState,
  clientId: number,
): ClientParams | undefined {
  return selectors.selectById(state, clientId) || undefined;
}

export const selectCampaignsOfCurrentClient = createSelector(
  selectCurrentClientId,
  selectAllCampaigns,
  (currentClientId, allCampaigns) =>
    allCampaigns.filter(campaign => campaign.client === currentClientId) || [],
);

export const selectClientName = (
  state: RootState,
  clientId: number,
): string => {
  const client = selectors.selectById(state, clientId);
  return client?.name || '';
};
