import { mode } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react';
import components from './components';
import colors from './colors';

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  fontWeights: {
    bold: 600,
  },
  radii: {
    xs: '0.125rem',
    sm: '0.25rem',
  },
  colors: {
    ...colors,
    nexusTeal: {
      100: '#00B5B1',
      200: '#009c99',
    },
    nexusBlue: {
      100: '#0073e5',
      200: '#0080FF',
      300: '#0A2756',
    },
    nexusOrange: {
      100: '#EFB6A3',
      200: '#d6a392',
      300: '#EF5B2B',
      400: '#d65227',
      500: '#bd4922',
    },
    nexusMint: {
      100: '#99FDD1',
      200: '#88e3bc',
      300: '#61f2c7',
      400: '#57d9b2',
      500: '#3d997d',
    },
    nexusLightTeal: {
      100: '#B2E9E7',
    },
    nexusElectricBeige: {
      100: '#FFEDCA',
    },
    nexusYellow: {
      100: '#f9e5b2',
      200: '#f7de9a',
      300: '#f5d683',
      400: '#F3CE6B',
      500: '#f1c653',
    },
    graphite: {
      100: 'rgba(49,50,51,0.05)',
      200: 'rgba(49,50,51,0.1)',
      300: 'rgba(49,50,51,0.25)',
      400: 'rgba(49,50,51,0.5)',
      500: '#313233',
      600: '#4a4b4d',
      700: '#636366',
    },
    white: {
      100: '#fff',
      200: '#F2F2F2 ',
    },
    dark: {
      100: '#212223',
      200: '#0c0c0f',
      300: '#e3e7f0',
    },
    selected: 'rgba(18, 22, 77, 0.1)',
    primary: '#faa002',
    secondary: '#fff',
    navyBlue: {
      200: '#414470',
      300: '#292D5E',
      400: '#12164d',
      500: '#101345',
      600: '#0E113D',
      800: '#0A0D2E',
    },
    avatar: {
      bg: '#fedbeb',
      text: '#8f4567',
    },
    status: {
      active: {
        bg: '#e4fdd6',
        text: '#75a761',
      },
      inactive: {
        bg: '#fedbdb',
        text: '#d56868',
      },
    },
  },
  styles: {
    global: props => ({
      'html, body': {
        bg: mode('white.200', 'dark.200')(props),
        color: mode('graphite.500', 'dark.300')(props),
      },
      '*': {
        scrollbarWidth: 'auto',
        scrollbarColor: 'transparent',
      },

      '*::-webkit-scrollbar': {
        width: '12px',
      },

      '*::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '*::-webkit-scrollbar-corner': { background: 'rgba(0, 0, 0, 0)' },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: mode('graphite.400', 'graphite.600')(props),
        borderRadius: '10px',
        borderWidth: '3px',
        borderColor: mode('white.200', 'dark.200')(props),
        borderStyle: 'solid',
      },
      'input::-webkit-datetime-edit': {
        cursor: 'text',
      },
      'input::-webkit-calendar-picker-indicator': {
        cursor: 'pointer',
        position: 'absolute',
        right: '.25em',
        opacity: 0.6,
        filter: mode('invert(0)', 'invert(1)')(props),
      },
      '.recharts-surface': {
        overflow: 'visible',
      },
      '.recharts-cartesian-grid line': {
        stroke: mode('graphite.300', 'graphite.600')(props),
      },
      '.recharts-cartesian-axis line': {
        stroke: mode('graphite.300', 'graphite.600')(props),
      },
      '.recharts-cartesian-axis text': {
        fill: mode('graphite.400', 'graphite.600')(props),
        fontSize: 'xs',
        fontWeight: 'bold',
      },
      '.recharts-legend-item-text': {
        fontSize: 'md',
        fontWeight: 'bold',
        color: mode('graphite.400', 'graphite.600')(props),
      },
      '.recharts-tooltip-cursor': {
        stroke: mode('graphite.300', 'graphite.600')(props),
      },
      '.recharts-line path': {
        strokeWidth: 3,
      },
      '.recharts-line-dots circle': {
        strokeWidth: 3,
        fill: mode('graphite.50', 'graphite.800')(props),
      },
    }),
  },
  shadows: {
    base: '0px 5px 20px 0px rgba(49, 49, 77, 0.05)',
  },
  components,
});

export default theme;
