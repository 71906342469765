import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ControlledInputNumber } from 'components';

const LineitemGeneralFrequencyCappingCount = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { control } = useFormContext();
  const {
    field: { value: isActive },
  } = useController({
    name: 'general.frequencyCapping.enabled',
    control,
  });

  React.useEffect(() => {
    setIsDisabled(isActive => !isActive);
  }, [isActive]);

  return (
    <ControlledInputNumber
      name='general.frequencyCapping.count'
      label='Count'
      isDisabled={isDisabled}
      min={0}
      precision={0}
      step={1}
      {...props}
    />
  );
};

LineitemGeneralFrequencyCappingCount.displayName =
  'Lineitem.general.frequencyCapping.count';

export { LineitemGeneralFrequencyCappingCount };
