import { mode } from '@chakra-ui/theme-tools';
export const ButtonStyles = {
  baseStyle: {
    fontWeight: 500,
  },
  sizes: {},
  variants: {
    primary: {
      bg: 'nexusTeal.100',
      color: 'white.100',
      _hover: {
        bg: 'nexusTeal.200',
        _disabled: {
          bg: 'graphite.100',
        },
      },
    },
    secondary: {
      bg: 'nexusBlue.200',
      color: 'white.100',
      _hover: {
        bg: 'graphite.600',
        _disabled: {
          bg: 'graphite.100',
        },
      },
    },
    accept: props => ({
      bg: mode('nexusMint.300', 'nexusMint.100')(props),
      color: mode('white', 'nexusMint.500')(props),
      _hover: {
        bg: mode('nexusMint.400', 'nexusMint.200')(props),
        _disabled: {
          bg: 'graphite.100',
        },
      },
    }),
    deny: props => ({
      bg: mode('nexusOrange.300', 'nexusOrange.100')(props),
      color: mode('white', 'nexusOrange.500')(props),
      _hover: {
        bg: mode('nexusOrange.400', 'nexusOrange.200')(props),
        _disabled: {
          bg: 'graphite.100',
        },
      },
    }),
    tertiary: props => ({
      bg: mode('graphite.200', 'graphite.500')(props),
      color: mode('graphite.500', 'dark.300')(props),
      _hover: {
        bg: mode('graphite.300', 'graphite.600')(props),
        _disabled: {
          bg: 'graphite.100',
        },
      },
    }),
    send: props => ({
      bg: mode('nexusTeal.100', 'nexusTeal.100')(props),
      color: mode('white', 'white')(props),
      _hover: {
        bg: mode('nexusTeal.200', 'nexusTeal.200')(props),
        _disabled: {
          bg: 'graphite.100',
        },
      },
    }),
    danger: {
      bg: 'red.500',
      color: 'white.100',
    },
    unstyled: {
      display: 'inline-flex',
      fontWeight: 'bold',
    },
  },
  defaultProps: {},
};
