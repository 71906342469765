import { Button, useToast, Tooltip } from '@chakra-ui/react';
import { userHasRequiredPermissions } from 'hooks';
import * as React from 'react';
import { FiDownload } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

type DownloadButton = {
  action: any;
  permissions?: Array<string>;
  title: string;
  description: string;
};

const DownloadButton = ({
  action,
  permissions,
  title,
  description,
}: DownloadButton): JSX.Element => {
  const dispatch = useDispatch();
  const toast = useToast();
  const hasPermissions = userHasRequiredPermissions(permissions);

  const [isRequestPending, setIsRequestPending] = React.useState(false);

  const handleDownloadAction = async () => {
    try {
      setIsRequestPending(true);
      await dispatch(action);
    } catch (error) {
      toast({
        position: 'bottom',
        isClosable: true,
        title: 'An error occurred',
        description: error?.message + ': Operation was unsuccessfull.',
        status: 'error',
      });
    }
    setIsRequestPending(false);
  };
  return (
    <Tooltip label={description} hasArrow placement='top' openDelay={500}>
      <span>
        <Button
          colorScheme='gray'
          onClick={handleDownloadAction}
          ml={3}
          isLoading={isRequestPending}
          isDisabled={!hasPermissions}
          minWidth='12ch'
          leftIcon={<FiDownload />}
        >
          {title}
        </Button>
      </span>
    </Tooltip>
  );
};

export default DownloadButton;
