import * as React from 'react';
import { Abbr } from 'components';
import {
  STAT_CPM,
  STAT_CPC,
  STAT_CTR,
  STAT_BUDGETSPEND,
  STAT_TRAFFICAFFORDABLE,
  STAT_TRAFFICNOTAFFORDABLE,
  STAT_MEDIANFLOORPRICE,
  STAT_VIEWSRATE,
} from 'store/statistics';

/**
 * Stringi doklejane za wyświetlaną wartością;
 * np. dla Budget Spend: 1234$
 */
const SUFFIXES: { [key: string]: string } = {
  [STAT_BUDGETSPEND]: '$',
  [STAT_CPM]: '$',
  [STAT_CPC]: '$',
  [STAT_MEDIANFLOORPRICE]: '$',
};

/**
 * Klucze, które mają zostać przeliczone na procenty
 */
const PERCENTAGES = [
  STAT_CTR,
  STAT_TRAFFICAFFORDABLE,
  STAT_TRAFFICNOTAFFORDABLE,
  STAT_VIEWSRATE,
];

/**
 * Konfiguracja Intl.NumberFormat do formatowania liczb
 */
export const SMALLNUMBER = Intl.NumberFormat('pl-PL', {
  maximumFractionDigits: 4,
});

export const NUMBER = Intl.NumberFormat('pl-PL', {
  maximumFractionDigits: 2,
});

export const LARGENUMBER = Intl.NumberFormat('pl-PL', {
  maximumFractionDigits: 0,
});

export function getPercentageIncrease(numA: number, numB: number): number {
  const percentage = ((numA - numB) / numB) * 100;
  return percentage;
}

function generateSuffix(dataKey: string): string | JSX.Element {
  if (dataKey in SUFFIXES) return <small>{SUFFIXES[STAT_BUDGETSPEND]}</small>;
  if (PERCENTAGES.includes(dataKey)) return <small>%</small>;
  return '';
}

export function formatValue(
  value: string | number | undefined,
): string | JSX.Element {
  if (typeof value === 'undefined' || !value) return '0';
  if (typeof value === 'number' || typeof value === 'bigint') {
    if (value >= 1000) return LARGENUMBER.format(value);
    if (value >= 1) return NUMBER.format(value);
    if (value >= 0.01) return SMALLNUMBER.format(value);
    if (value > 0) return <Abbr text='&lt; 0.01' tooltip={value} />;
    return '0';
  }

  return value;
}

/**
 * Formatowanie wyświetlanej wartości statystyki do postaci przyjaznego stringa / komponentu JSX
 */
export function formatStatisticValue(
  dataKey: string,
  value: string | number | undefined,
): string | JSX.Element {
  if (PERCENTAGES.includes(dataKey) && typeof value === 'number') {
    value = value * 100;
  }
  const formattedValue = formatValue(value);
  const suffix = generateSuffix(dataKey);

  return (
    <>
      {formattedValue}
      {suffix}
    </>
  );
}

export const fomratNumberSpace = (value: number | string): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
