import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './users.consts';
import { UserParams } from './users.types';

const fetchByIdHandler = async ({ userId }: { userId: number }) => {
  const user = await adserver({
    url: `/user/${userId}/`,
    headers: {},
  });

  return {
    ...user.data,
  };
};

export const fetchAllUsers = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/users/', headers: {} });
    return response.data;
  }),
);

export const fetchById = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ userId }: { userId: number }) => {
    const response = await adserver({
      url: `/users/${userId}/`,
      headers: {},
    });
    return response.data;
  }),
);

export const create = createAsyncThunk(
  REDUCER_KEY + '/create',
  withErrorHandling(async (values: UserParams) => {
    const response = await adserver({
      url: '/users/',
      method: 'POST',
      data: values,
      headers: {},
    });
    return response.data;
  }),
);

export const update = createAsyncThunk(
  REDUCER_KEY + '/update',
  withErrorHandling(async (data: UserParams) => {
    const { id: userId } = data;

    const response = await adserver({
      url: `/users/${userId}/`,
      method: 'PATCH',
      data,
      headers: {},
    });
    return response.data;
  }),
);

export const remove = createAsyncThunk(
  REDUCER_KEY + '/remove',
  withErrorHandling(async (userId: number) => {
    await adserver({
      url: `/users/${userId}/`,
      method: 'DELETE',
      headers: {},
    });
    return userId;
  }),
);

export const setCurrentUser = createAsyncThunk(
  REDUCER_KEY + '/current',
  withErrorHandling(async (userId: number) => {
    if (!userId) return;
    const user = await fetchByIdHandler({ userId });
    return user;
  }),
);

export const removeAllUsers = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
