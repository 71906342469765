import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, Box, Grid, Icon } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
  MdOutlineHandshake,
  MdOutlinePeopleAlt,
  MdNotes,
  MdOutlineLogout,
  MdOutlineShoppingCart,
  MdOutlineShopTwo,
  MdOutlineSettings,
  MdOutlineBusinessCenter,
} from 'react-icons/md';
import {
  CAMPAIGN_LIST,
  PROPOSAL_LIST,
  PRODUCT_LIST,
  MARKET_LIST,
  CLIENT_LIST,
  USER_LIST,
  AD_FORMAT_LIST,
} from 'routes';
import {
  CAN_VIEW_CAMPAIGNS,
  CAN_VIEW_PROPOSALS,
  CAN_EDIT_CAMPAIGNS,
  CAN_EDIT_CLIENTS,
  CAN_EDIT_PRODUCTS,
  CAN_EDIT_USERS,
  CAN_EDIT_PRODUCT_METADATA,
} from 'consts/permissions';
import { logout } from 'store/user';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import Logo from './Logo';

const MenuOption = ({ label, icon, ...props }) => (
  <Grid
    templateAreas={`
    "icon"
    "label"
  `}
    gap={2}
    alignItems='center'
    {...props}
  >
    <Box
      width='fit-content'
      gridArea='icon'
      mx={5}
      px={2}
      bg='nexusBlue.200'
      borderRadius='md'
      _hover={{
        bg: 'nexusBlue.100',
      }}
    >
      <Icon
        as={icon}
        color='white.100'
        display='block'
        boxSize={5}
        mx={3}
        my={2}
      />
    </Box>
    <Box gridArea='label' fontSize='xs' textAlign='center'>
      {label}
    </Box>
  </Grid>
);
MenuOption.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.any,
};

const Link = ({ label, icon, to, permissions }) => {
  const hasPermissions = userHasRequiredPermissions(permissions);
  return hasPermissions ? (
    <MenuOption label={label} icon={icon} as={RouterLink} to={to} />
  ) : null;
};

Link.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.any,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

const User = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.user);

  const handleClick = () => dispatch(logout());

  if (!isLoggedIn) return null;

  return (
    <>
      <MenuOption
        label='Wyloguj'
        icon={MdOutlineLogout}
        onClick={handleClick}
      />
    </>
  );
};

const Sidebar = ({ gridArea }) => {
  return (
    <Grid
      gridArea={gridArea}
      py={4}
      gap={6}
      w='min-content'
      height='100%'
      bg='nexusBlue.300'
      color='white.100'
      templateRows='1fr min-content'
    >
      <Stack direction='column' spacing={4} marginTop={-4}>
        <RouterLink to={CAMPAIGN_LIST}>
          <Logo mt={8} mb={4} px={4} />
        </RouterLink>
        <Link
          to={MARKET_LIST}
          permissions={[CAN_EDIT_CAMPAIGNS]}
          label='Market'
          icon={MdOutlineShoppingCart}
        />
        <Link
          to={PROPOSAL_LIST}
          permissions={[CAN_VIEW_PROPOSALS]}
          label='Transakcje'
          icon={MdOutlineHandshake}
        />
        <Link
          to={CAMPAIGN_LIST}
          permissions={[CAN_VIEW_CAMPAIGNS]}
          label='Kampanie'
          icon={MdNotes}
        />
        <Link
          to={PRODUCT_LIST}
          permissions={[CAN_EDIT_PRODUCTS]}
          label='Produkty'
          icon={MdOutlineShopTwo}
        />
        <Link
          to={CLIENT_LIST}
          permissions={[CAN_EDIT_CLIENTS]}
          label='Klienci'
          icon={MdOutlineBusinessCenter}
        />
        <Link
          to={USER_LIST}
          permissions={[CAN_EDIT_USERS]}
          label='Użytkownicy'
          icon={MdOutlinePeopleAlt}
        />
        <Link
          to={AD_FORMAT_LIST}
          permissions={[CAN_EDIT_PRODUCT_METADATA]}
          label='Ustawienia produktów'
          icon={MdOutlineSettings}
        />
      </Stack>
      <User />
    </Grid>
  );
};

Sidebar.propTypes = {
  gridArea: PropTypes.string,
};

export default Sidebar;
