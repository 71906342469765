import { useState, useEffect } from 'react';
import { useAppSelector } from 'hooks';
import {
  selectCurrentProposalStatus,
  selectProposalStatusById,
} from 'store/proposals';

export const useProposalStatus = (requiredStatus: string[]): boolean => {
  const prposalStatus = useAppSelector(selectCurrentProposalStatus);
  const [hasRequiredStatus, setHasRequiredStatus] = useState(false);

  useEffect(() => {
    if (prposalStatus === null || prposalStatus === undefined) {
      return;
    }
    requiredStatus.includes(prposalStatus)
      ? setHasRequiredStatus(true)
      : setHasRequiredStatus(false);
  }, [prposalStatus, requiredStatus]);

  return hasRequiredStatus;
};

export const useProposalStatusID = (
  requiredStatus: string[],
  proposalId: number,
): boolean => {
  const prposalStatus = useAppSelector(state =>
    selectProposalStatusById(state, proposalId),
  );
  const [hasRequiredStatus, setHasRequiredStatus] = useState(false);

  useEffect(() => {
    if (prposalStatus === null || prposalStatus === undefined) {
      return;
    }

    requiredStatus.includes(prposalStatus)
      ? setHasRequiredStatus(true)
      : setHasRequiredStatus(false);
  }, [prposalStatus, requiredStatus]);

  return hasRequiredStatus;
};
