import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './adformats.consts';
import { AdFortmatParams } from './adformats.types';

const fetchByIdHandler = async ({ adFormatId }: { adFormatId: number }) => {
  const user = await adserver({
    url: `/adformats/${adFormatId}`,
    headers: {},
  });

  return {
    ...user.data,
  };
};

export const fetchAllAdFormats = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/adformats/', headers: {} });
    return response.data;
  }),
);

export const fetchById = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ adFormatId }: { adFormatId: number }) => {
    const response = await adserver({
      url: `/adformats/${adFormatId}/`,
      headers: {},
    });
    return response.data;
  }),
);

export const create = createAsyncThunk(
  REDUCER_KEY + '/create',
  withErrorHandling(async (values: AdFortmatParams) => {
    const response = await adserver({
      url: '/adformats/',
      method: 'POST',
      data: values,
      headers: {},
    });
    return response.data;
  }),
);

export const update = createAsyncThunk(
  REDUCER_KEY + '/update',
  withErrorHandling(async (data: AdFortmatParams) => {
    const { id: adFormatId } = data;

    const response = await adserver({
      url: `/adformats/${adFormatId}/`,
      method: 'PATCH',
      data,
      headers: {},
    });
    return response.data;
  }),
);

export const remove = createAsyncThunk(
  REDUCER_KEY + '/remove',
  withErrorHandling(async ({ adFormatId }: { adFormatId: number }) => {
    await adserver({
      url: `/adformats/${adFormatId}/`,
      method: 'DELETE',
      headers: {},
    });
    return adFormatId;
  }),
);

export const setCurrentAdFormat = createAsyncThunk(
  REDUCER_KEY + '/current',
  withErrorHandling(async (adFormatId: number) => {
    if (!adFormatId) return;
    const user = await fetchByIdHandler({ adFormatId });
    return user;
  }),
);

export const removeAllAdFormats = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
