import * as React from 'react';
import { AnyObjectSchema } from 'yup';
import {
  OPERATION_CREATE,
  OPERATION_UPDATE,
  OPERATION_DELETE,
  ENTITY_CAMPAIGN,
  ENTITY_LINEITEM,
  ENTITY_CREATIVE,
} from 'store/history';
import { campaignSchema } from 'components/Campaign';
import { lineitemSchema } from 'components/Lineitem';
import { creativeSchema } from 'components/Creative';
import { StatusIcon } from 'components';

/** Statusy typów operacji dla <Operation />  */
export const OPERATION_TYPES: { [key: string]: JSX.Element } = {
  [OPERATION_CREATE]: <StatusIcon message='Stwórz' color='green.400' />,
  [OPERATION_UPDATE]: <StatusIcon message='Zmień' color='blue.400' />,
  [OPERATION_DELETE]: <StatusIcon message='Skasuj' color='red.400' />,
};

/** Schema Yup dla danego typu edytowanej treści  */
export const ENTITY_SCHEMAS: { [key: string]: AnyObjectSchema } = {
  [ENTITY_CAMPAIGN]: campaignSchema,
  [ENTITY_LINEITEM]: lineitemSchema,
  [ENTITY_CREATIVE]: creativeSchema,
};

/** Kolory dla typów zmiany wartości pola  */
export const CHANGE_COLOR_SCHEME: { [key: string]: string } = {
  added: 'green',
  changed: 'blue',
  removed: 'red',
};

/** Statusy typów operacji dla <History.FieldChange />  */
export const CHANGE_TYPES: { [key: string]: JSX.Element } = {
  added: <StatusIcon message='Dodano' color='green.400' />,
  changed: <StatusIcon message='Zmieniono' color='blue.400' />,
  removed: <StatusIcon message='Usunięto' color='red.400' />,
};
