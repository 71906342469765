import { MultiOptionField, SingleField } from 'components/ModalInfo.Fields';
import { useAppSelector } from 'hooks';
import * as React from 'react';
import { selectadFormatsAsOptions } from 'store/adformats';

const ProductAdFormatInfo = ({
  value,
  gridColumn,
  ...props
}: {
  value: number[];
  gridColumn: any;
  [key: string]: any;
}): JSX.Element => {
  const adFormats = useAppSelector(selectadFormatsAsOptions);

  if (typeof value === 'undefined' || value.length === 0) {
    return (
      <SingleField
        header='Formaty reklamowe'
        value='Brak'
        gridColumn={gridColumn}
        {...props}
      />
    );
  }

  const elements = adFormats
    .filter(({ id }: { id: number }) => value.includes(id))
    .map(({ name }: { name: string }) => name);

  return (
    <MultiOptionField
      header='Formaty reklamowe'
      elements={elements}
      gridColumn={gridColumn}
      {...props}
    />
  );
};

ProductAdFormatInfo.displayName = 'Product.adFormatInfo';

export { ProductAdFormatInfo };
