import * as React from 'react';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';

interface InputDateRangeProps {
  value: Date[] | undefined;
  min: Date | undefined;
  max: Date | undefined;
  onChange: (value: any) => void;
}

export const InputDateRange = ({
  value,
  min,
  max,
  onChange,
}: InputDateRangeProps): JSX.Element => {
  const [selectedDates, setSelectedDates] = React.useState<Date[]>(
    value ? value : [],
  );

  React.useEffect(() => {
    if (typeof onChange === 'function') onChange(selectedDates);
  }, [selectedDates]);

  return (
    <RangeDatepicker
      selectedDates={selectedDates}
      onDateChange={setSelectedDates}
      minDate={min}
      maxDate={max}
      configs={{
        dateFormat: 'dd.MM.yyyy',
        dayNames: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
        monthNames: [
          'Styczeń',
          'Luty',
          'Marzec',
          'Kwiecień',
          'Maj',
          'Czerwiec',
          'Lipiec',
          'Sierpień',
          'Wrzesień',
          'Październik',
          'Listopad',
          'Grudzień',
        ],
        firstDayOfWeek: 1,
      }}
    />
  );
};
