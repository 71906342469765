import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import adFusionLogo from '../NAdFusion_logo_horizontal.png';

const Logo = ({ ...props }) => {
  return (
    <Box {...props}>
      <img src={adFusionLogo} />
    </Box>
  );
};

Logo.propTypes = {
  variant: PropTypes.string,
};

export default Logo;
