import { mode } from '@chakra-ui/theme-tools';

export default {
  baseStyle: {
    field: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    dropdown: {
      boxShadow: 'md',
      borderColor: 'graphite.400',
      borderWidth: '1px',
      _focus: {
        outline: 'none',
        borderColor: 'graphite.500',
        boxShadow: 'unset',
      },
    },
    options: {
      maxHeight: '20ch',
      overflow: 'auto',
    },
    option: {
      _hover: {
        background: 'graphite.100',
      },
    },
    icon: {
      right: 0,
    },
    separator: {
      w: '1px',
      h: '100%',
      background: 'graphite.400',
    },
    tags: {
      width: 'fit-content',
      flexWrap: 'wrap',
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: 'sm',
        borderRadius: 'sm',
        minH: 8,
        h: 'auto',
        py: 1,
        pl: 3,
        pr: 0,
      },
      icon: {
        width: 4,
        mx: 2,
      },
      dropdown: {
        borderRadius: 'sm',
      },
      options: {
        fontSize: 'sm',
        py: 1,
      },
      option: {
        px: 3,
        py: 1,
      },
      tags: {
        m: '-1px',
        ml: -2,
      },
      tag: {
        margin: '2px',
      },
    },
  },
  variants: {
    outline: props => ({
      field: {
        background: mode('white.100', 'dark.100')(props),
        color: mode('graphite.500', 'dark.300')(props),
        borderColor: 'graphite.400',
        borderWidth: '1px',
        _hover: {
          borderColor: 'graphite.500',
        },
        _focus: {
          borderColor: 'graphite.500',
          boxShadow: 'unset',
        },
      },
      dropdown: {
        background: mode('white.100', 'dark.100')(props),
        color: mode('graphite.500', 'dark.300')(props),
        '*::-webkit-scrollbar-thumb': {
          borderColor: mode('white.100', 'dark.100')(props),
        },
      },
    }),
    flushed: props => ({
      field: {
        background: mode('white.100', 'dark.100')(props),
        color: mode('graphite.500', 'dark.300')(props),
        borderColor: 'yellow.400',
        borderWidth: '1px',
        _hover: {
          borderColor: 'yellow.500',
        },
        _focus: {
          borderColor: 'yellow.500',
          boxShadow: 'unset',
        },
      },
      dropdown: {
        background: mode('white.100', 'dark.100')(props),
        color: mode('graphite.500', 'dark.300')(props),
        '*::-webkit-scrollbar-thumb': {
          borderColor: mode('white.100', 'dark.100')(props),
        },
      },
    }),
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};
