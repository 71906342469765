import React from 'react';
import { Helmet } from 'react-helmet-async';

export type TitleProps = {
  name: string | number;
};

export type ScritpProps = {
  src: string;
};

export const Title = ({ name }: TitleProps): JSX.Element => {
  return (
    <Helmet>
      <title>{name ? name + ' | ' : ''}NAD</title>
    </Helmet>
  );
};

export const Script = ({ src }: ScritpProps): JSX.Element => {
  return (
    <Helmet>
      <script src={src} />
    </Helmet>
  );
};
