import * as React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { useController, useFormContext, useFormState } from 'react-hook-form';
import { formContext } from 'components/Form.Context';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import parse from 'html-react-parser';

interface Field {
  name: string;
  label?: string | React.ReactNode;
  helper?: string | React.ReactNode;
  gridArea?: string;
  gridColumn?: string;
  permissionsToEdit: string[];
  defaultValue?: string | number | boolean | string[] | number[] | boolean[];
  oldValue?: string | number;
  [key: string]: any;
}
const withReactHookFormController = (
  Component: React.ElementType,
  valueFieldName = 'value',
): React.ElementType => {
  const ReactHookFormController = ({
    name,
    label,
    helper,
    gridArea,
    gridColumn,
    permissionsToEdit: fieldPermissions = [],
    defaultValue,
    oldValue = undefined,
    ...props
  }: Field): JSX.Element => {
    const { control } = useFormContext();
    const { permissionsToEdit, disableAll } = React.useContext(formContext);
    const userCanEdit = userHasRequiredPermissions([
      ...permissionsToEdit,
      ...fieldPermissions,
    ]);
    const { isSubmitting } = useFormState({ control });
    const {
      field: { onChange, onBlur, value },
      fieldState: { invalid, error },
    } = useController({
      name,
      control,
    });

    const handleChange = (e: any) => {
      onChange(e);
    };

    return (
      <FormControl
        isInvalid={invalid}
        isReadOnly={!userCanEdit}
        isDisabled={isSubmitting || !userCanEdit}
        gridArea={gridArea}
        gridColumn={gridColumn}
        width='auto'
        display='inline-block'
      >
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        <Component
          onChange={handleChange}
          onBlur={onBlur}
          name={name}
          isDisabled={!userCanEdit || disableAll}
          defaultValue={defaultValue}
          {...{ [valueFieldName]: value }}
          {...props}
        />
        {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        {helper && (
          <FormHelperText>
            {typeof helper === 'string' ? parse(helper) : helper}
          </FormHelperText>
        )}
        {oldValue !== undefined && (
          <FormHelperText color='nexusYellow.400'>
            Poprzednia wartość: {oldValue}
          </FormHelperText>
        )}
      </FormControl>
    );
  };
  return ReactHookFormController;
};

export { withReactHookFormController };
