import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';
import { selectAllClients } from 'store/clients';

const CampaignClient = ({ ...props }: { [key: string]: any }): JSX.Element => {
  const clients = useAppSelector(selectAllClients);

  const options = React.useMemo(() => {
    return clients?.map(({ id, name }: { id: number; name: string }) => ({
      id,
      name,
    }));
  }, [clients]);

  return (
    <ControlledSelect
      name='client'
      label='Klient'
      options={options}
      {...props}
    />
  );
};

CampaignClient.displayName = 'Campaign.client';

export { CampaignClient };
