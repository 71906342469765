export const REDUCER_KEY = 'proposal';

export const DRAFT = 'BUYER_DRAFT';
export const BUYER_REVIEW_REQUESTED = 'BUYER_REVIEW_REQUESTED';
export const PUBLISHER_REVIEW_REQUESTED = 'PUBLISHER_REVIEW_REQUESTED';
export const BUYER_ACCEPTANCE_REQUESTED = 'BUYER_ACCEPTANCE_REQUESTED';
export const NEGOTIATIONS_FINISHED = 'NEGOTIATIONS_FINISHED';
export const TERMINATED = 'TERMINATED';

export const PUBLISHER_GENERATE_ORDER_REQUESTED =
  'PUBLISHER_GENERATE_ORDER_REQUESTED';
export const BUYER_SIGNATURE_REQUESTED = 'BUYER_SIGNATURE_REQUESTED';
export const PUBLISHER_CORRECTION_REQUESTED = 'PUBLISHER_CORRECTION_REQUESTED';
export const PUBLISHER_ACCEPTANCE_REQUESTED = 'PUBLISHER_ACCEPTANCE_REQUESTED';
export const BUYER_CORRECTION_REQUESTED = 'BUYER_CORRECTION_REQUESTED';

export const FINALIZED = 'FINALIZED';

export const NEGOTIATIONS = [
  DRAFT,
  BUYER_REVIEW_REQUESTED,
  PUBLISHER_REVIEW_REQUESTED,
  BUYER_ACCEPTANCE_REQUESTED,
  NEGOTIATIONS_FINISHED,
  TERMINATED,
];

export const ORDER = [
  PUBLISHER_GENERATE_ORDER_REQUESTED,
  BUYER_SIGNATURE_REQUESTED,
  PUBLISHER_CORRECTION_REQUESTED,
  PUBLISHER_ACCEPTANCE_REQUESTED,
  BUYER_CORRECTION_REQUESTED,
  FINALIZED,
];
